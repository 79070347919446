import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import "./../../../SCSS/Cms.scss";
import { useEffect } from "react";

function AboutUs() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box className="cmsWrapper">
      <NavBar />

      <Box className="aboutTop-sec" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} md={7}>
            <h2 style={{ marginBottom: "8px" }}>About Us</h2>
            <h4>
              Motilal Oswal Financial Services Limited{" "}
              <span style={{ fontWeight: 400 }}>
                - Corporate Agent (Composite - IRDAI Registration no - CA0579)
              </span>
            </h4>
          </Grid>
          <Grid xs={12} md={5} textAlign={"center"}>
            <img src="./images/about-us-top.svg" alt="abt-img" />
          </Grid>
        </Grid>
      </Box>

      <Box className="who-sec" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs="auto">
            <Box className="whoweBox">
              <h2>
                Who
                <br /> We <br /> Are
              </h2>
            </Box>
          </Grid>
          <Grid xs>
            <p className="mb-2">
              Motilal Oswal Financial Services (MOFSL), is a well-diversified
              financial services company focused on wealth creation through
              knowledge. We are a multi-faceted financial services company with
              a presence in over 550 cities through 2500+ business locations;
              ably managed by a team of over 10,500 employees.
            </p>
            <p className="mb-2">
              This network of business locations coupled with people across
              business units and a diverse range of financial expertise works
              synergistically to provide a host of products and services across
              Retail and Institutional Broking, Private Wealth, Investment
              Banking, Private Equity, Asset Management, Insurance intermediary
              & Corporate agency and Home Finance.
            </p>
            <p>
              All these businesses are headquartered in a single location at
              Motilal Oswal Tower, Gokhale Sayani Road, Prabhadevi, Mumbai to
              provide sharing and synergy of knowledge under one roof.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="vision-valueBox" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={6}>
            <Box className="greyBox">
              <h3 className="mb-2">Our Vision</h3>
              <p>
                To be a well-respected and preferred global financial services
                organisation enabling wealth creation for all our customers.
              </p>
            </Box>
          </Grid>
          <Grid xs={6} textAlign={"center"}>
            <img
              src="./images/vision.svg"
              alt="vision"
              className="vision-img"
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={6} textAlign={"center"}>
            <img
              src="./images/mission.svg"
              alt="mission"
              className="vision-img"
            />
          </Grid>
          <Grid xs={6}>
            <Box className="greyBox">
              <h3 className="mb-2">Our Values</h3>
              <p>
                Integrity, Team work, Meritocracy, Passion & Attitude and
                Excellence in execution.
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="mofsl-details" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 className="mb-3">
              Motilal Oswal Financial Services Limited details in short –
            </h3>
            <p>
              Share Price at NSE -{" "}
              <span>₹ 665.70 as on 1st Dec 2022 closing</span>
            </p>
            <p>
              No of MOFSL Offices - <span>100+</span>
            </p>
            <p>
              Total Client base - <span>30 Lakh Plus</span>
            </p>
            <p>
              Asset under advisory - <span>₹ 3.2 Lakhs Crore </span>
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="mofsl-verticals" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 className="mb-4">MOFSL Verticals and Products marketing</h3>
          </Grid>
          <Grid xs={6}>
            <h6 className="mb-2">1. Capital Markets</h6>
            <ul className="asset_li mb-4">
              <li>Retail Broking & Distribution</li>
              <li>Depository Participant</li>
              <li>Research Analyst </li>
              <li>Institutional Equity </li>
              <li>Investment Banking</li>
            </ul>
            <h6 className="mb-2">2. Asset Management</h6>
            <ul className="asset_li mb-4">
              <li>Asset Management </li>
              <li>Private Equity</li>
              <li>Private Wealth</li>
            </ul>
          </Grid>
          <Grid xs={6}>
            <h6 className="mb-2">
              3. Insurance intermediary Corporate Agent (Composite - IRDAI
              Registration no-CA0579)
            </h6>
            <ul className="asset_li mb-4">
              <li>Life insurance</li>
              <li>General Insurance</li>
              <li>Health Insurance</li>
            </ul>
            <h6 className="mb-2">4. Home Finance</h6>
            <h6 className="mb-2">5. Fund Based Business </h6>
            <ul className="asset_li mb-4">
              <li>PE funds</li>
            </ul>
          </Grid>
          <Grid xs={12}>
            <h6 className="mb-2">Customer reach – Customer growth Story</h6>
            <ul className="asset_li mb-4">
              <li>We serve ~3.2 million registered customers</li>
              <li>Our Private wealth team serves 4700+ HNI & UHNI Customers</li>
              <li>Home Finance team has assisted to 47,500+ customers.</li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="digital-presence" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 className="mb-3">Our Digital Presence</h3>
            <p className="mb-2">
              <span>Social Media –</span>
              Customers reach us over Facebook, Twitter, Instagram, LinkedIn,
              Telegram, YouTube and Quora.
            </p>
            <p className="mb-2">
              <span>Digital Foot prints -</span>
              We have our own website and trading apps like: MO Investor, MO
              Trader App and Research 360 platforms for distribution and
              services of our products.
            </p>
            <p className="mb-2">
              <span>Effectiveness of email campaign -</span>
              99% email delivery rate with 13% open rate.
            </p>
            <p>
              <span>Website reach – </span>
              Avg. traffic on website is 20-21 Lakh/month, Avg. leads 5-6K
              monthly, conversion is avg. 19%/month.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="humancapital_valueSec" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 className="mb-3">Our Human Capital Value</h3>
            <h4 style={{ textAlign: "center" }}>Key People</h4>
          </Grid>
          <Grid xs={6}>
            <Box className="shadowBox">
              <img src="images/Ajay-Menon.jpg" alt="" width="150" />
              <h5>Ajay Menon</h5>
              <p>Managing Director & CEO, Broking & Distribution</p>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box className="shadowBox">
              <img src="images/Harsh-Joshi.jpg" alt="" width="150" />
              <h5>Harsh Joshi</h5>
              <p>Executive Director, Head-Ifa & Investment Products (Bod)</p>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="keyResearch-sec" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 className="mb-3">
              Our Financials – Market share, Revenue Growth, Profitability
            </h3>
            <p className="mb-2">
              Motilal Oswal share has a market capitalization of ₹ 9,891.82 Cr.
            </p>
            <p className="mb-2">
              Net increase of ₹ 37,378 Lakhs YOY for year ended 31 March 2022.
            </p>
            <p>With profit of ₹ 86,413 Lakhs for year ended 31 March 2022.</p>
          </Grid>
        </Grid>
      </Box>

      <Box className="marketShare-sec" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 className="mb-3">
              Our Key Strength - Data Research & Advisory
            </h3>
            <p className="mb-2">
              Over 30 years of equity experience, stock market analysis &
              research reports. We provide in depth information on positions,
              margin and your overall holdings with our equity research reports.
              With real-time and consolidated views. Presence of 31000+ Research
              reports, 260+ Stocks covered, 21+Sectors covered and over 40
              Report types.
            </p>
            <p className="mb-2">
              Experience based & knowledge based Insurance solicitation and wide
              choice selection option from the number of products. We have IRDA
              Licenced and trained Specified persons pan India to solicit the
              policies.
            </p>
            <p>
              We do risk analysis, gap analysis, plan design, predictive
              modelling and data management, Insurance advisory reports to our
              customers based on analytics of existing insurance portfolio and
              providing advisory on well researched plans across the spectrum of
              Life, General and Health Insurance.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </Box>
  );
}

export default AboutUs;
