import { createSlice } from "@reduxjs/toolkit";
import { TTermSlice } from "../../../Type/Term/TTermSlice";
import { TERM_REDUCERS } from "./Reducer/TermReducer";

const initialState: TTermSlice = {
  PROPOSER_DATA: {
    name: { value: "", warning: false },
    dob: { value: "01-7-2004", warning: false },
    mobile: { value: "", warning: false },
    emergencyPhone: { value: "", warning: false },
    email: { value: "", warning: false },
    gender: { value: "", warning: false },
    pincode: { value: "", warning: false },
    proposer_occupation: { value: "", warning: false },
    proposer_qualification: { value: "", warning: false },
  },
  QUOTE_FIELDS_DATA: {
    payMode: "12",
    sortBy: "Low-High",
    payUpto: "75",
    monthlyPayout: "40000",
    coverageUpto: "75",
    sumAssured: "2500000",
    planType: "1",
    income_period: "10",
  },
  DROPDOWN_DATA: {
    ANNUAL_INCOME: [],
    INCOME_PERIOD: [],
    GENDER: [
      { label: "M", value: "Male" },
      { label: "F", value: "Female" },
    ],
    SMOKE: [
      { label: "Y", value: "Yes" },
      { label: "N", value: "No" },
    ],
    AGE: [],
    PAY_MODE_DATA: [
      { label: "12", value: "Monthly" },
      { label: "4", value: "Quarterly" },
      { label: "2", value: "Half-yearly" },
      { label: "1", value: "Yearly" },
      { label: "5", value: "Single Pay" },
    ],
    COVERAGE_UPTO: [],
    PAY_UPTO: [],
    SORT_BY: [
      { label: "Low-High", value: "Low-High" },
      { label: "High-Low", value: "High-Low" },
    ],
    MONTHLY_PAYOUT: [],
    SUM_ASSURED: [],
    OCCUPATION_DATA: [],
    QUALIFICATION_DATA: [],
  },
  ADD_FORM: {
    annualIncome: { value: "", warning: false },
    smoke: { value: "", warning: false },
    gender: { value: "", warning: false },
    age: { value: "", warning: false },
    pincode: { value: "", warning: false },
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
  },
  ADD_FORM_RESPONSE: {
    quote_no: "",
    module: "",
    insurance_type: "",
    sum_assured: "",
    pincode: "",
    annual_income: "",
    name: "",
    email: "",
    mobile: "",
    city: "",
    state: "",
    max_sum_assured: 180000000,
    gender: "M",
    smoker: "Y",
    term: "",
    age: "21",
    dob_date: "",
    dob_month: "",
    dob_year: "",
    cover_upto: 75,
    frequency: 12,
    income: "6000000",
    cover_pay_upto: "75",
    policy_covers: 1,
    monthly_payout: 40000,
    income_period: 15,
    increasing_level: 0,
  },
  SELECTED_QUOTE_DATA: {
    premiumDetails: {
      product_desc: "",
      term: "",
      pay_term: "",
      sum_assured: 0,
      wop_rider_premium: 0,
      wop_rider_gst: 0,
      wopPremium: 0,
      ci_rider_premium: 0,
      ci_rider_gst: 0,
      ciPremium: 0,
      acc_rider_premium: 0,
      acc_rider_gst: 0,
      accPremium: 0,
      riders: {
        TF001: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF003: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF002: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF004: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF005: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
      },
      premiumAmount: 0,
      Total_basePremium: 0,
      Total_taxAmount: 0,
      taxAmount: "",
      finalPremium: 0,
      annunalTargetPremium: 0,
    },
    CompanyDetails: {
      company_code: "",
      name: "",
      short_desc: "",
      logo: "",
      claim_ratio: "",
    },
    SpecialFeatureLists: [],
    productDetailLists: null,
    buyOnlineLink: {
      method: "",
      buy_online_link: "",
      redirection_type: "",
    },
    productDetails: {
      id: 0,
      product_name: "",
      brochure: "",
      product_code: "",
      rate_calc_method: "",
      proposal_method: "",
      tax_calc_method: "",
      discount_calc_method: "",
      rating: 0,
      product_desc: "",
      buy_online_code: "",
      special_feature_codes: "",
      prem_calc_action: "",
      tax_calc_action: "",
      discount_calc_action: "",
      term_form_validation: {
        frequency: 0,
        modal_factor: 0,
        min_sum_assured: 0,
        max_sum_assured: 0,
        min_age: 0,
        max_age: 0,
        min_term: 0,
        max_term: 0,
        min_p_cess_age: 0,
        max_p_cess_age: 0,
      },
      CompanyProductMaps: {
        company_code: "",
      },
    },
    sumAssured: "",
    QuoteNo: "",
  },
  RIDERS_BENEFITS: {
    riders_benefits_response: {
      redirectionUrl: "",
      base_rate: 0,
      modal_factor: "",
      conversion_factor: 0,
      premiumAmount: 0,
      finalPremium: 0,
      pay_term: "",
      term: "",
      appno: "",
      quote_id: "",
      uwtype: "",
      TF001: {
        sum_assured: 0,
        min_sa_percentage: 0,
        max_sa_percentage: 0,
        sa_percentage: 0,
        interval: 0,
        premium: 0,
        min_sum_assured: 0,
        max_sum_assured: 0,
        description: "",
        free: 0,
        rider_pay_term: "",
        rider_term: "",
        show_sum_insured: "",
        status: "",
      },
      TF002: {
        sum_assured: 0,
        min_sa_percentage: 0,
        max_sa_percentage: 0,
        sa_percentage: 0,
        interval: 0,
        premium: 427,
        min_sum_assured: 0,
        max_sum_assured: 0,
        description: "",
        free: 0,
        rider_pay_term: "",
        rider_term: "",
        show_sum_insured: "",
        status: "",
      },
      TF003: {
        sum_assured: 0,
        min_sa_percentage: 0,
        max_sa_percentage: 0,
        sa_percentage: 0,
        interval: 0,
        premium: 427,
        min_sum_assured: 0,
        max_sum_assured: 0,
        description: "",
        free: 0,
        rider_pay_term: "",
        rider_term: "",
        show_sum_insured: "",
        status: "",
      },
      quote_no: "",
      sum_insured: "",
      frequency: "",
    },
    local_value: {
      tf001Status: false,
      tf001Value: "1000000",
      tf002Status: false,
      tf002Value: "1000000",
      tf003Status: false,
      tf003Value: "1000000",
      tf004Status: false,
      tf004Value: "1000000",
      tf005Status: false,
      tf005Value: "1000000",
      BNF001Status: false,
      BNF002Status: false,
      BNF003Status: false,
    },
  },
};

const ageData = [];
for (let i = 18; i <= 65; i++) {
  ageData.push({
    label: `${i}`,
    value: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.AGE = ageData;

let incomePeriodData = [];
for (let i = 1; i <= 30; i++) {
  incomePeriodData.push({
    label: `${i}`,
    value: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.INCOME_PERIOD = incomePeriodData;

export const TermSlice = createSlice({
  name: "Term",
  initialState,
  reducers: TERM_REDUCERS,
});

export default TermSlice.reducer;
