export const COLORS = {
  black: "#000000",
  white: "#ffffff",
  primary: "#FF881C",
  secondary: "#8D66B5",
  grey: "rgba(0, 0, 0, 0.5)",
  lightGrey: "#F1F1F1",
  darkGrey: "rgba(0,0,0,0.65)",
  lightRed: "#FFEFF0",
};
