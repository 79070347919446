import CallRoundedIcon from "@mui/icons-material/CallRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { COLORS } from "../../../SupportingFiles/colors";
import "./Home.scss";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);

  const handleKeyDown = (event:any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };
  return (
    <Box className="homepage-wrapper">
      <NavBar />

      <Box className="product-section">
        <Box className="product-sec-inner">
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12} textAlign={"center"} paddingTop={"60px"}>
              <h1 className="mb-2">
                Secure your Today and Protect your Tomorrow
              </h1>
              <h6>
                Tell us what you need and our team will help you get the right
                insurance you deserve.
              </h6>
              <Box
                display={"flex"}
                gap={"30px"}
                flexWrap={"wrap"}
                justifyContent={"center"}
                marginTop={"36px"}>
                <Box
                  className="ip_box healthinsurance"
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
                  }}>
                  <p>
                    Health <br /> Insurance
                  </p>
                </Box>
                <Box
                  className="ip_box terminsurance"
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
                  }}>
                  <p>
                    Term <br /> Insurance
                  </p>
                </Box>
                <Box
                  className="ip_box carinsurance"
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
                  }}>
                  <p>
                    Car <br /> Insurance
                  </p>
                </Box>
                <Box
                  className="ip_box bikeinsurance"
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
                  }}>
                  <p>
                    Two Wheeler <br /> Insurance
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className="why-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h2 style={{ textAlign: "center", marginBottom: "30px" }}>
              Why should you Buy from Motilal Oswal ?
            </h2>
            <ul>
              <li className="featureBox">
                <img src="./images/feature1.svg" alt="feature" />
                <h5>Customer Centric Unbiased Advice</h5>
              </li>
              <li className="featureBox">
                <img src="images/feature2.svg" alt="feature" />
                <h5>Best Possible Premium Rates</h5>
              </li>
              <li className="featureBox">
                <img src="images/feature3.svg" alt="feature" />
                <h5>Get Insured with minimum paperwork</h5>
              </li>
              <li className="featureBox">
                <img src="images/feature4.svg" alt="feature" />
                <h5>100% Reliable Regulated by IRDAI</h5>
              </li>
              <li className="featureBox">
                <img src="images/feature5.svg" alt="feature" />
                <h5>Get safely Insured in under 2 mins</h5>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="insured-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h2 style={{ textAlign: "center", marginBottom: "30px" }}>
              Lets help you to get insured.
            </h2>
            <ul className="worklist-list">
              <li>
                Fill in Your Details
                <span>
                  Fill in your details and get premium Quotes from top rated
                  Insures instantly.
                </span>
              </li>
              <li>
                Compare Quotes & Select Plan
                <span>
                  From all available optoins, choose a quote that best suits
                  your requirements & budgets.
                </span>
              </li>
              <li>
                Make Payment & Sit Back
                <span>
                  Pay Online & get your policy right away in your Inbox.
                </span>
              </li>
            </ul>
            <Box className="insured-bg">
              <img src="images/how-we-works.svg" alt="hw_works" />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="partners-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h2 style={{ textAlign: "center", marginBottom: "30px" }}>
              Leading Insurers for your financial freedom.
              <br /> Our Partners
            </h2>
            <ul className="partners-list">
              <li>
                <img
                  src="./images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurerlogo/aditya_birla_capital.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg"
                  alt=""
                />
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="subscribe-section">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={6}>
            <h2 style={{ marginBottom: "30px" }}>Queries or doubts?</h2>
            <Box className="query-box" style={{ marginBottom: "30px" }}>
              <Box className="input-group subscribebox">
                <input
                  type="text"
                  className="inputField"
                  placeholder="Email address"
                  onKeyDown={handleKeyDown}
                />
                {/* <span className="error d-">Enter valid email.</span> */}
              </Box>
              <Box className="button_box">
                <button className="btn font-heading" type="button">
                  Subscribe
                </button>
              </Box>
            </Box>
            <p style={{ color: COLORS.grey, fontSize: "16px" }}>
              * Your email is fully secure with us. We don’t sell.
            </p>
          </Grid>
          <Grid xs={6}>
            <Box className="contactbox">
              <Box className="supportBox email">
                <span>
                  <EmailRoundedIcon />
                </span>
                <h5>E-Mail</h5>
                <a
                  href="mailto:mofslinsurance@motilaloswal.com"
                  target="_blank">
                  <span>mofslinsurance@motilaloswal.com</span>
                </a>
              </Box>
              <Box className="supportBox call">
                <span>
                  <CallRoundedIcon />
                </span>
                <h5>Call</h5>
                <a href="tel:02240548000" target="_blank">
                  <span>022 40548000</span>
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </Box>
  );
}

export default Home;
