// DatePicker.tsx
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { subDays, subMonths, subYears } from "date-fns";
import { useEffect, useState } from "react";
import {
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";

const DatePicker = ({
  title,
  value,
  value_update,
  attrName,
  error_message = "Select",
  warn_status,
  class_name,
  min_date = 15,
  max_date = 18,
  date_validation_type = "YEARS",
  disabled = false,
  default_date = new Date(),
}: {
  title: string;
  value: any;
  value_update: Function;
  attrName: any;
  error_message?: string;
  warn_status?: boolean;
  class_name: string;
  min_date?: number;
  max_date?: number;
  date_validation_type?: "YEARS" | "MONTHS" | "DAYS";
  disabled?: boolean;
  default_date?: Date;
}) => {
  console.log(min_date);
  console.log(max_date);
  
  const [minDate, setMinDate] = useState<Date>(subYears(new Date(), min_date));
  const [maxDate, setMaxDate] = useState<Date>(subYears(new Date(), max_date));
  const [localValue, setLocalValue] = useState<Date | null>(null);
console.log(minDate);
console.log(maxDate);

  useEffect(() => {
    if (!isEmpty(value)) {
      const parts = value.split("-");
      if (parts.length === 3) {
        const formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const dateObject = new Date(formattedDateString);
        if (!isNaN(dateObject.getTime())) {
          setLocalValue(dateObject);
        } else {
          console.error("Invalid date string");
        }
      } else {
        console.error("Invalid date format");
      }
    } else {
      setLocalValue(null);
    }
  }, [value]);

  useEffect(() => {
    switch (date_validation_type) {
      case "YEARS":
        setMinDate(subYears(new Date(), min_date));
        setMaxDate(subYears(new Date(), max_date));
        break;
      case "MONTHS":
        setMinDate(subMonths(new Date(), min_date));
        setMaxDate(subMonths(new Date(), max_date));
        break;
      case "DAYS":
        setMinDate(subDays(new Date(), min_date));
        setMaxDate(subDays(new Date(), max_date));
        break;
      default:
        break;
    }
  }, [min_date, max_date, date_validation_type]);

  return (
    <div className={`datepicker inputField ${class_name}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          disabled={disabled}
          format="dd-MM-yyyy"
          sx={{ width: "100%" }}
          label={title}
          value={localValue}
          onChange={(selectedDate) => {
            const formattedDate = FORMAT_DATE_DD_MM_YYYY(`${selectedDate}`);
            value_update(attrName, formattedDate);
          }}
          closeOnSelect
          defaultCalendarMonth={default_date}
          minDate={minDate}
          maxDate={maxDate}
        />
        {warn_status ? <span className="error">{error_message}</span> : null}
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
