export enum COMMON_ROUTES {
  HOME = "/",
  PRIVACY_POLICY = "/privacy-policy",
  DISCLAIMER = "/disclaimer",
  REFUND_AND_CANCELLATION = "/cancellation-and-refund",
  ABOUT_US = "/about-us",
  CONTACT_US = "/contact-us",
  COMPLAINT = "/complaint",
  CLAIMS = "/claims",
  FAQ = "/faq",

  HEALTH_FORM = "/form/health-insurance-form",
  CAR_FORM = "/form/car-insurance-form",
  TW_FORM = "/form/two-wheeler-form",
  TERM_FORM = "/form/term-insurance-form",

  PRODUCT_HEALTH = "/health-insurance",
  PRODUCT_CAR = "/car-insurance",
  PRODUCT_TW = "/two-wheeler-insurance",
  PRODUCT_TERM = "/term-insurance",

  CONTACT = "/contact-us",
  CAREER = "/career",
  TERM_AND_CONDTION = "/terms-and-conditions",
}
