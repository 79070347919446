import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import FAQ from "../../../Component/FAQ/FAQ";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "./../../../SCSS/ProductPage.scss";
import CarProductContainer from "../../../Container/CarProductContainer/CarProductContainer";

function CarProductPage() {
  const isMobile = useIsMobile();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);

  const defaultShow = 10;
  const [showAllFaqs, setShowAllFaqs] = useState(false);

  const faqs = [
    {
      title: `What is car insurance?`,
      desc: `Car insurance is a type of policy that provides financial protection against damages to your car, third-party liabilities, theft, and personal accidents. `,
    },
    {
      title: "Is car insurance mandatory in India? ",
      desc: `Yes, as per the Motor Vehicles Act, having at least third-party liability insurance is mandatory for all car owners in India.`,
    },
    {
      title:
        "What is the difference between third-party and comprehensive car insurance? ",
      desc: (
        <>
          <ul>
            <li>
              <strong>Third-Party Insurance</strong>: Covers damages and
              injuries caused to a third party.
            </li>
            <li>
              <strong>Comprehensive Insurance</strong>: Includes third-party
              coverage as well as protection against own damages, theft, fire,
              and natural calamities.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What is Zero Depreciation cover in car insurance?",
      desc: `Zero Depreciation cover ensures that you receive the full claim amount without any deductions for the depreciation of car parts.`,
    },
    {
      title: "Can I transfer my car insurance to a new owner if I sell my car?",
      desc: `Yes, few insurers allow the policy holder to transfer car insurance policy to the new owner. One needs to Inform the insurer about the sale, and they will guide through the transfer process. The new owner will need to provide relevant documents, Premium and information for the transfer.`,
    },
    {
      title: "How can I renew my car insurance policy?",
      desc: "You can easily renew your car insurance policy online through our website or mobile app. Simply enter your policy details and make the payment. Renewing your policy on time ensures continuous coverage.",
    },
    {
      title: "What documents are required to file a car insurance claim?",
      desc: (
        <>
          Typically, you need the following documents:
          <br />
          <ul>
            <li>• Copy of the insurance policy</li>
            <li>• FIR (in case of theft or major accidents)</li>
            <li>• Driving license</li>
            <li>• Registration certificate of the car</li>
            <li>• Claim form duly filled and signed</li>
          </ul>
        </>
      ),
    },
    {
      title: "How is the premium for car insurance calculated?",
      desc: `The premium is calculated based on factors such as the car's make and model, age, location, type of coverage, and add-on covers.`,
    },
    {
      title: "What should I do if I lose my car insurance policy document?",
      desc: `If you lose your car insurance policy document, you can request a duplicate copy from your insurer by providing your policy details and identification proof.`,
    },
    {
      title: "What is No Claim Bonus (NCB) in car insurance?",
      desc: `No Claim Bonus is a discount on the premium for not making any claims during the previous policy term. It accumulates with each claim-free year and can significantly reduce your premium.`,
    },
    {
      title: "Can I get car insurance for an old or second-hand car?",
      desc: `Yes, you can get insurance for an old or second-hand car. The premium will be based on the car's current market value, age, and condition.`,
    },
    {
      title: "What factors affect the car insurance premium?",
      desc: `Several factors influence the car insurance premium, including the car's make and model, age, location, type of coverage, add-ons, and your claims history.`,
    },
    {
      title: "Is it possible to switch my car insurance provider?",
      desc: `Yes, you can switch your car insurance provider at the time of renewal. Compare different policies and choose the one that best suits your needs.`,
    },
    {
      title: "What is an IDV in car insurance?",
      desc: `IDV (Insured Declared Value) is the current market value of your car. It is the maximum sum assured by the insurer in case of total loss or theft of the car.`,
    },
    {
      title: "Can I get car insurance if I don't have a valid driving license?",
      desc: `Yes, you can buy an insurance policy and cover your car. However you cannot drive the car on roads.`,
    },
    {
      title: "What happens if my car insurance policy expires?",
      desc: `If your car insurance policy expires, you won't be covered for any damages or liabilities. You should renew it promptly to avoid any gaps in coverage.`,
    },
    {
      title: "Can I add accessories to my car insurance policy?",
      desc: `Yes, you can cover electrical and non-electrical accessories in your car insurance policy. Inform your insurer and get an add-on cover for accessories.`,
    },
    {
      title: "How do I claim car insurance for theft?",
      desc: `In case of theft, file an FIR at the nearest police station, inform your insurer, and submit the required documents, including the FIR copy, policy document, and claim form.`,
    },
    {
      title: "What is the role of an insurance surveyor in car insurance?",
      desc: `An insurance surveyor assesses the damage to your car and verifies the extent of the loss to determine the claim amount.`,
    },
    {
      title: "Are there any discounts available on car insurance?",
      desc: `Yes, insurers offer discounts on car insurance for installing anti-theft devices, being a member of a recognized automobile association, and maintaining a no-claim record.`,
    },
    {
      title: "What is the procedure for changing the car insurance provider?",
      desc: `To change your car insurance provider, compare different policies, choose a new insurer, and initiate the renewal process before your current policy expires.`,
    },
  ];

  return (
    <Box className="productWrapper res-wrapper ">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec mt-6" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <Box className="formBox">
              <CarProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row"
          marginBottom={"30px"}
          alignItems={"center"}
        >
          <Grid xs={12} md={9}>
            <h3 className="mb-2">
              Protecting Your Drive, Wherever the Road Leads
            </h3>
            <p>
              Owning a car is great, but accidents and other unexpected events
              can be expensive. Car insurance helps you avoid these costs by
              covering repairs if your car has met with an accident, stolen, or
              damaged by bad weather. In India, it is a legal requirement to
              have car insurance before driving on public roads. We can help you
              find the right coverage to protect yourself financially. Car
              insurance offered through our portal, provides a financial safety
              net in such situations.
            </p>
          </Grid>
          <Grid xs={12} md={3} textAlign={"center"}>
            <img
              src="./images/car-product.svg"
              alt=""
              style={{ maxWidth: "270px", width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">Benefits of Car Insurance: </h3>
            <ol className="productpage_OLpoints">
              <li>
                <span>Financial Protection -</span> It covers the cost of
                repairs to your vehicle due to accidents, vandalism, or theft,
                which can save you from significant out-of-pocket expenses.
              </li>
              <li>
                <span>Liability Coverage -</span> It protects you from legal and
                financial responsibilities if you cause damage to another
                person's car or injure someone in an accident.
              </li>
              <li>
                <span>P.A. Coverage -</span> Motor policy comes with Personal
                Accidental coverage which provides compensation in case of death
                or disability of the owner/driver arising out of an accident
                while driving the insured vehicle.
              </li>
              <li>
                <span>Peace of Mind -</span> Knowing you are financially
                protected against unexpected events while driving can give you
                peace of mind.
              </li>
              <li>
                <span>Legal Compliance -</span> Car insurance is often a legal
                requirement in many jurisdictions, ensuring you are compliant
                with the law when driving on public roads.
              </li>
            </ol>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">Types of Car Insurance: </h3>
            <ul className="pp_darkHeadlist">
              <li>
                <span>Comprehensive Cover -</span> Comprehensive Insurance cover
                provides extensive coverage beyond basic liability and accident
                insurance, including protection against theft, vandalism,
                natural disasters, and damage not caused by an accident.
              </li>
              <li>
                <span>Standalone Own Damage Policy -</span> When only Third
                Party policy in force, car owner can buy a Standalone Own Damage
                policy to cover for damages caused to their vehicle as well.
              </li>
              <li>
                <span>Third-Party Liability (Mandatory) -</span> Third-party car
                insurance is the most basic and legal form of car insurance
                policy you will need. As the name suggests, this will only cover
                damage and injuries caused to a third-party and won't cover your
                own damages.
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">Get a Quote in 3 Easy Steps: </h3>
            <ul className="productpage_listpoints">
              <li>Visit our portal and enter your Car details. </li>
              <li>Compare quotes from various motor insurance companies </li>
              <li>
                Choose the plan that best suits your needs and complete the
                online purchase.
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">
              Add on you can add while purchasing a car insurance.
            </h3>
            <ol className="productpage_OLpoints">
              <li>
                <span>Zero Depreciation Cover -</span> Covers the full value of
                repairs and replacements without considering depreciation.
              </li>
              <li>
                <span>Return to Invoice Cover -</span> Compensates with the
                invoice value of the car in case of total loss or theft.
              </li>
              <li>
                <span>Engine Protection Cover -</span> Covers damages to the
                car's engine and gearbox.
              </li>
              <li>
                <span>Tyre Protect Cover -</span> Covers damages to tyres such
                as bursts, bulges, and cuts.
              </li>
              <li>
                <span>Breakdown Assistance -</span> Provides services during
                breakdowns such as towing, minor repairs, and fuel delivery etc.
              </li>
              <li>
                <span>Daily Conveyance Benefit -</span> Offers a daily allowance
                for transportation or a standby vehicle when your car is in the
                garage for repairs.
              </li>
              <li>
                <span>Key and Lock Protect -</span> Covers the cost of repairing
                or replacing the car’s lock and key in case of theft or loss.
              </li>
              <li>
                <span>Loss to Personal Belongings -</span> Compensates the loss
                of personal items in your car against theft.
              </li>
              <li>
                <span>Roadside Assistance Cover (RSA) -</span> Offers services
                like towing, emergency fuel delivery, flat tire assistance,
                battery jump-starts, and help with minor mechanical repairs,
                taxi support, pick-up facility, and legal assistance if needed.
              </li>
              <li>
                <span>Consumables Cover -</span> Covers the cost of consumables
                like nuts, bolts, screws, oils, etc., used during repairs.
              </li>
              <li>
                <span>No Claim Bonus (NCB) Protection Cover -</span> Allows you
                to retain your NCB discount even after making a claim,
                protecting your discount accumulated over claim-free years. Etc.
              </li>
            </ol>
            <p className="Note">
              *Add on may vary subject to the insurance provider.
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">Why Choose Us?</h3>
            <ol className="productpage_OLpoints">
              <li>
                <span>Tailored Solutions -</span> Customized plans to meet your
                specific needs.
              </li>
              <li>
                <span>Affordable Premiums -</span> Competitive pricing with
                flexible payment options.
              </li>
              <li>
                <span>Digital Convenience -</span> Easy online policy purchase
                and renewal process
              </li>
              <li>
                <span>Reliable Partners -</span> Collaborations with top to
                bring you the best plans.
              </li>
              <li>
                <span>Customer-Centric Approach -</span> Dedicated support and
                easy claim settlement process.
              </li>
            </ol>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-4">Our Partners</h3>
            <ul className="insurer-partnersList">
              <li>
                <img
                  src="./images/insurers/Go-Digit-General-Insurance.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurers/HDFC-ERGO-General-Insurance-Co-Ltd.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurers/ACKO_general_insurance.svg"
                  alt=""
                />
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-4">Car Insurance FAQ’s</h3>
            <FAQ data={showAllFaqs ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid xs={12} textAlign={"right"}>
            <CustomButton
              className="primaryBtn large "
              fullWidth={false}
              variant="contained"
              text={showAllFaqs ? "Read Less..." : " Read More..."}
              onClick={() => setShowAllFaqs(!showAllFaqs)}
            />
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default CarProductPage;
