import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MHome from "../../Page/Mobile/MHome/MHome";
import Home from "../../Page/Desktop/Home/Home";

function HomeContainer() {
  const isMobile = useIsMobile();

  return isMobile ? <MHome /> : <Home />;
}

export default HomeContainer;
