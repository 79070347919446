import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import "./../../../SCSS/Cms.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import FAQ from "../../../Component/FAQ/FAQ";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import { HOME_SERVICES } from "../../../Services/Home/HomeServices";
import { toast } from "react-toastify";
import { isEmpty, validateFullName, validateMobileNumber } from "../../../SupportingFiles/HelpingFunction";

function Claims() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useIsMobile();

  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    claimfor: { value: string; warn_status: boolean };
    claimdesc: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    claimfor: { value: "", warn_status: false },
    claimdesc: { value: "", warn_status: false },
  });

  const claimForData = [
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "Term Insurance",
      value: "Term Insurance",
    },
    {
      label: "Car Insurance",
      value: "Car Insurance",
    },
    {
      label: "Bike Insurance",
      value: "Bike Insurance",
    },
  ];
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  console.log("form", formFields);
  
  
  const Submit_claim = () => {
    const toastID = toast.loading("Please wait...");
    let params = {
      username: formFields.name.value,
      number: formFields.mobile.value,
      claim: formFields.claimfor.value,
      query: formFields.claimdesc.value,
    };
    const onSuccess = (data: any) => {
      toast.dismiss(toastID);
      const results = data.status;
      const error = results;
      if (error) {
        toast.error("Something went wrong.");
      } else {
        setFormFields({
          name: { value: "", warn_status: false },
          mobile: { value: "", warn_status: false },
          claimfor: { value: "", warn_status: false },
          claimdesc: { value: "", warn_status: false },
        });
        toast.success("Thank you for your query! Our team will reach out to you shortly.");
      }
    };
    const onError = () => {};
    HOME_SERVICES.Submit_claim(onSuccess, onError, params);
  };

  const validateForm = () => {
    let data = { ...formFields };
    data = {
      ...data,
      name: {
        ...data.name,
        warn_status: !validateFullName(data.name.value),
      },
      mobile: {
        ...data.mobile,
        warn_status: isEmpty(data.mobile.value),
      },
      claimfor: {
        ...data.claimfor,
        warn_status: isEmpty(data.claimdesc.value),
      },
      claimdesc: {
        ...data.claimdesc,
        warn_status: isEmpty(data.claimdesc.value),
      }
    };
    setFormFields(data);
    if (
      data.name.warn_status === false ||
      data.mobile.warn_status === false ||
      data.claimfor.warn_status === false ||
      data.claimdesc.warn_status === false
    ) {
      Submit_claim();
    }
  };

  return (
    <Box className="cmsWrapper res-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"50px"}>
          <Grid xs={12}>
            <h3 style={{ textAlign: "center", marginBottom: "8px" }}>
              Need Claim Assistance?
            </h3>
            <p style={{ textAlign: "center" }}>We are here to help you!</p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row"
          marginBottom={"50px"}
          alignItems={"center"}
        >
          <Grid xs={12} md={6}>
            <Box className="contactCard">
              <Box className="contactcard-Inner">
                <h5>You can speak to an expert right now:</h5>
                <p style={{ marginBottom: "5px" }}>
                  Helpline No. : <a href="tel:+022 40548000">022 40548000</a>
                </p>
                <p>
                  E-mail ID :
                  <a href={`mailto:mofslinsurance@motilaloswal.com`}>
                    mofslinsurance@motilaloswal.com
                  </a>
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="formBox">
              <h5 className="mb-5">Submit a Claim Ticket</h5>
              <RKTextField
                class_name="inputField mb-5"
                title={"Name"}
                validation_type="NAME"
                value={formFields.name.value}
                attrName={"name"}
                value_update={updateFieldsState}
                warn_status={formFields.name.warn_status}
                error_message={"Enter Your Name"}
              />
              <RKTextField
                class_name="inputField mb-5"
                title={"Mobile"}
                value={formFields.mobile.value}
                validation_type="NUMBER"
                max_length={10}
                attrName={"mobile"}
                value_update={updateFieldsState}
                warn_status={formFields.mobile.warn_status}
                error_message={"Enter Mobile Number"}
              />
              <SelectDropdown
                class_name="inputField mb-5"
                title="Claim ticket for"
                attrName={"claimfor"}
                value={formFields.claimfor.value}
                value_update={updateFieldsState}
                data={claimForData}
                warn_status={formFields.claimfor.warn_status}
                // error_message={"Please Write Something"}
              />
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                Please Provide Details
              </p>
              <TextArea
                class_name="inputField mb-5"
                title={""}
                value={formFields.claimdesc.value}
                attrName={"claimdesc"}
                value_update={updateFieldsState}
                warn_status={formFields.claimdesc.warn_status}
                placeholder="Write here..."
                error_message={"Please Write Something"}
              />
              <CustomButton
                className="primaryBtn large mb-1"
                fullWidth={false}
                variant="contained"
                text="Submit Details"
                onClick={() => {validateForm()}}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Claims;
