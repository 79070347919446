import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useEffect, useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import FAQ from "../../../Component/FAQ/FAQ";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import SearchDropdown from "../../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { AddTermDTO } from "../../../Services/Term/TermDTO";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  FORMAT_DD_MM_YYYY,
  calculateDOBFromAge,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TTermAddForm } from "../../../Type/Term/TTermAddForm";
import "./../../../SCSS/ProductPage.scss";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { TERM_ROUTES } from "../../../Routing/Path/TermRoutes";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import TermProductContainer from "../../../Container/TermProductContainer/TermProductContainer";

function TermProductPage() {
  const isMobile = useIsMobile();
  const { ADD_FORM } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const [formData, setFormData] = useState<TTermAddForm>(ADD_FORM);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);

    dispatch(TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState()));
  }, []);

  const annual_income_data: { label: string; value: string }[] = [];
  for (let i = 2; i <= 100; i++) {
    const value = `${i * 100000}`;
    let label = "";
    if (i <= 99) {
      label = `  ₹  ${i} Lakhs`;
    } else {
      const croreValueLabel = i / 100;
      label = `  ₹  ${croreValueLabel} Crore`;
    }
    annual_income_data.push({ label: label, value: value });
  }

  const age_data: { label: string; value: string }[] = [];
  for (let i = 18; i <= 65; i++) {
    age_data.push({ label: `${i} years`, value: `${i}` });
  }

  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const smokinStatus_data = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const updateMasterState = (attrName: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "pincode"
            ? !validatePincode(value)
            : attrName === "mobile"
            ? !validateMobileNumber(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let data: TTermAddForm = { ...formData };
    let hasError = false;
    data = {
      ...data,
      annualIncome: {
        ...data.annualIncome,
        warning: isEmpty(data.annualIncome.value),
      },
      smoke: {
        ...data.smoke,
        warning: isEmpty(data.smoke.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
      age: {
        ...data.age,
        warning: isEmpty(data.age.value),
      },
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
    };
    if (
      data.annualIncome.warning ||
      data.smoke.warning ||
      data.gender.warning ||
      data.age.warning ||
      data.pincode.warning ||
      data.mobile.warning
    ) {
      hasError = true;
    }
    if (!hasError) {
      add_form(data);
    }
    setFormData({ ...data });
  };

  const add_form = (data: TTermAddForm) => {
    const onSuccess = (res: any) => {
      console.log(res);
      const response = res?.results?.response;
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          ADD_FORM: { ...data },
          ADD_FORM_RESPONSE: { ...res?.results?.response },
          PROPOSER_DATA: {
            ...Term.PROPOSER_DATA,
            mobile: { value: data.mobile.value, warning: false },
            pincode: data.pincode,
          },
        })
      );

      // setLoader(false);
      window.location.href = `${FRONTEND_DOMAIN}${TERM_ROUTES.QUOTE}?type=TM&quote_no=${response.quote_no}`;
    };
    const onError = (err: any) => {
      console.log("err", err);
      // setLoader(false);
    };
    const ageInDays = parseInt(data.age.value, 10);
    const dob = !isNaN(ageInDays)
      ? FORMAT_DATE_DD_MM_YYYY(calculateDOBFromAge(ageInDays))
      : "";

    let params: AddTermDTO = {
      lifeInsurance_module: "TermPlan",
      lifeInsurance_type: "Term Plan",
      termSmoker: data.smoke.value === "Yes" ? "Y" : "N",
      termAnnualIncome: `${data.annualIncome.value}`,
      termAnnualIncomeOther: `${data.annualIncome.value}`,
      termGender: data.gender.value === "Male" ? "M" : "F",
      termDob: `${FORMAT_DD_MM_YYYY(data.age.value)}`,
      termName: data.name.value,
      termMobile: data.mobile.value,
      termPincode: data.pincode.value,
    };
    console.log("data.age.value", params);

    // setLoader(true);
    TERM_SERVICES.addTermFormData(onSuccess, onError, params);
  };

  const defaultShow = 20;
  const [showAllFaqs, setShowAllFaqs] = useState(false);

  const faqs = [
    {
      title: "Why is term plan important?",
      desc: `With a term insurance plan, you are covered for a longer duration at low premium rates. It also provides financial security and protection for the entire family in case of the unforeseen demise of the policyholder. Also, you can receive optional coverage for accidental death or critical illnesses.`,
    },
    {
      title: "Can I invest in two term insurance policies?",
      desc: `There's no limit on the number of term insurance policies you can have, but insurance companies will look at your total coverage amount. As a rule of thumb, your coverage typically can't exceed 15 to 30 times your annual income, depending on your age`,
    },
    {
      title: "How do i decide my term insurance coverage?",
      desc: `The coverage amount depends on various factors such as the number of, dependents in the family, investment goals, affordability, and lifestyle. For calculating the term policy cover, you can opt for a sum assured that is 10-20 times your yearly income. Further, you should factor in your existing debt obligations and liabilities when you are deciding the coverage amount.`,
    },
    {
      title: "When should I invest in term insurance?",
      desc: `It is always best to invest in a term insurance policy as early as possible. For instance, the premiums tend to be higher for people in their 30s than for those in their 20s and so on. Buying insurance is always a good idea whether you are in your 20s, 30s, or above. But it's important to evaluate your personal financial situation, goals, and responsibilities. Each individual's circumstances are unique, and there is no one-size-fits-all answer.`,
    },
    {
      title: "What are the factors that affect term premium rates?",
      desc: (
        <>
          "Term insurance premium rates are computed through an underwriting
          process that employs the use of various statistical and mathematical
          calculations around the insured person. Some of the major parameters
          which can affect the term plan premium rates are:
          <br />
          <br /> Age: Younger individuals are at a lower risk of getting
          life-threatening diseases.
          <br />
          <br /> Younger people are offered lower life term insurance plan
          premium charges than an older person who is likely to claim much
          earlier.
          <br />
          <br /> Gender: As per various studies, women tend to live longer than
          men. So, many insurers charge a lower premium to women because they
          have a higher probability of paying more premiums.
          <br />
          <br /> Family’s Medical History: An individual whose family has a
          history of ailments such as cancer or heart attack, has an increased
          probability of contracting or diagnosing these diseases. This is the
          reason more instances of these ailments increase premium amounts.
          <br />
          <br />
          Smoking and Drinking Habits: The premium rates for smokers and alcohol
          users are more in comparison to non-smokers.
          <br />
          <br /> Policy Duration: If the policy term is longer, then you will
          end up paying a high amount of premiums as the insurer will have to
          cover your life for higher risk. And, a small policy tenure will have
          a low rate of premium as compared to a longer one.
          <br />
          <br /> Occupation: Individuals working in industries such as shipping,
          transport, gas, mining oil, etc. are at higher risk of accidents.
          Thus, in such cases, the term plan premium rates will be higher as
          compared to a desk job."
        </>
      ),
    },
    {
      title:
        "For a term plan purchased today, would the premium change in the future?",
      desc: `No, the premium amount you pay for a term plan will remain the same throughout the term of a policy.`,
    },
    {
      title: "How important is the gender of the life assured?",
      desc: `As per the experts, the average Indian woman lives longer than their male counterparts, the premium amount is lower for women than that of men belonging to the same age bracket. Some term plans also offer discounts to women on their premium amounts.`,
    },
    {
      title:
        "Why is the use of tobacco/nicotine a determining factor for term insurance plan premium rate?",
      desc: `Tobacco/nicotine use directly affects the life expectancy of a person. Considering that non-smoker is expected to live longer, their term insurance premiums are consequently lower.`,
    },
    {
      title: "What happens if I don't pay the term life insurance premium?",
      desc: `If premiums remain unpaid even after the grace period, the policy shall lapse along with its benefits. Some Insurers provide a revival period within which one can revive their lapsed policy.`,
    },
    {
      title:
        "What is the consequence of the life assured dying before the premiums of term insurance have been duly paid?",
      desc: `If the death of the life assured occurs before the payment of due premium, the policy will still be considered valid. In such a case, the death benefit reduces by the amount of due premiums that remain unpaid. The due amount is basically deducted from the sum assured on death.`,
    },
    {
      title:
        "What am I entitled to if the premiums are duly paid off and I survive the term period of a term plan?",
      desc: `Term insurance policies do not offer maturity benefits unless you have opted for the ‘return of premium’ variant. Therefore, if you were to outlive the policy term, you do not receive any benefits. This is why term insurance plan is rendered as a pure risk policy. It is the chance that you take to ensure a strong financial safety net for your loved ones in case you are not around for them`,
    },
    {
      title: "Can claims raised against term insurance be rejected?",
      desc: (
        <>
          "Yes, claims raised against a term insurance policy can be rejected
          under certain circumstances. Here are some common reasons why a claim
          might be rejected:
          <br />
          <br /> Non-disclosure of information: When applying for term
          insurance, you are required to provide accurate and complete
          information about your health, lifestyle, and other relevant details.
          If it is discovered that you withheld or misrepresented important
          information during the application process, the insurance company may
          reject the claim.
          <br />
          <br /> Suicide clause: Many term insurance policies have a suicide
          clause, typically within the first two years of the policy. If the
          insured person dies by suicide within that timeframe, the claim may be
          denied.
          <br />
          <br /> Policy exclusions: Certain situations or events may be excluded
          from coverage under the policy. For example, if the insured person
          dies as a result of participating in hazardous activities or illegal
          acts specified as exclusions in the policy, the claim could be
          rejected.
          <br />
          <br /> Lapse in premium payments: If you fail to pay the required
          premiums within the grace period specified by the insurance company,
          the policy may lapse, resulting in the claim being denied.
          <br />
          <br />
          Misrepresentation of age: If it is discovered that the insured
          person's age was misrepresented at the time of policy application, the
          insurance company may reject the claim or adjust the payout amount
          accordingly."
        </>
      ),
    },
    {
      title: "Can I surrender the policy and get back the premiums paid?",
      desc: `Surrender value is not paid for regular premium pay option. However, for limited pay it varies from company to company.`,
    },
    {
      title: "Can I avail loan on my term insurance policy?",
      desc: `No, you cannot avail for a loan on your term Insurance policy.`,
    },
    {
      title: "What is a term plan with a critical illness?",
      desc: `Term insurance plans with Critical illness cover provide a lump sum payment if you are diagnosed with any of the illnesses in the pre-specified list of the policy. This typically includes paralysis, heart attack, lung diseases, cancer, and others.`,
    },
    {
      title: "What is covered against critical illness in term insurance plan?",
      desc: `The critical illness cover provides you financial protection and security against several life-threatening medical conditions such as stroke, cancer, cardiovascular diseases, and kidney failures. This cover includes a lump sum payment if you are diagnosed with stated illnesses in the policy.`,
    },
    {
      title:
        "Should I take critical illness cover with term plan if I have health insurance?",
      desc: `Yes, you can take critical illness cover with a term insurance plan, if you already have health insurance plan, the critical illness cover provides lumpsum benefit if you are diagnosed with any of the listed critical illness. During any critical illness, this cover can be helpful to enhance an existing term insurance plan.`,
    },
    {
      title: "Should we take riders with a term insurance plan?",
      desc: `Yes, it is a good idea to opt for riders with term policy as it enhances the effectiveness of a base term plan at minimal premiums. They are optional attachments and adding them to your base plan can be very useful when an unexpected event takes place with life assured. You can have extra benefits under a single term insurance plan such as critical illness, waiver of premium, income on disability, accidental death benefit, etc. Choosing which riders to attach to your term policy shall depend on your risks, requirements, and preferences.`,
    },
    {
      title: "What are the types of term insurance riders?",
      desc: (
        <>
          "Let us take a look at some of the important term insurance riders
          that you can add to your base plan: <br />
          <br />
          Accidental Death Rider: One can opt for this rider to the base plan to
          increase his risk cover if death happens due to an accident during the
          rider’s tenure. Accelerated Death Benefit Rider: In this, the
          individual receives a partial advance amount of their sum assured in
          case of a terminal illness.
          <br />
          <br /> Critical Illness Rider: Critical Illness benefit is paid when
          you get diagnosed with kidney failure, heart attack, cancer, stroke,
          etc.
          <br />
          <br /> Family Income Benefit Rider: The family gets regular income
          each month if the life assured dies or meets with an accidental
          permanent total disability or is diagnosed with any critical
          illnesses. <br />
          <br />
          Permanent and Partial Disability Rider: Additional benefit equivalent
          to the rider sum assured is paid on total and permanent disability due
          to an accident.
          <br />
          <br /> Waiver of Premium Rider: This rider waives off future premium
          payments when assured can no longer pay premiums because of critical
          illness or permanent total disability, or death because of an
          accident."
        </>
      ),
    },
    {
      title: "Which rider is best with term insurance?",
      desc: `Although the selection of a rider depends on risks, your needs, and preferences; one of the best options is the waiver of premium rider. The rider protects policyholders against policy lapses in case of non-payment of insurance premiums due to an illness or under critical circumstances.`,
    },
    {
      title: "What is the cost of riders?	",
      desc: `Most of the riders are comparatively inexpensive. A rider usually costs around 5 to 10% of the total premium you pay for your base cover. There is no limit to the number of riders that you can add to your base term cover, but the premium amount on all the term insurance riders should not be more than 30% of your base premium.`,
    },
    {
      title: "What are the benefits of purchasing a rider?",
      desc: `Additional Coverage, Cost-effective, Tax-saving Benefit, Multiple benefits in a single policy etc according to the riders you choose`,
    },
    {
      title:
        "What are the factors which I should consider before buying a term insurance policy?",
      desc: `Before purchasing a term insurance plan you must check your insurance objectives, your income, your life insurance existing policies (if any), your assets, liabilities, and your expenses.`,
    },
    {
      title: "Can I cancel my policy immediately after I purchase it?",
      desc: `You can cancel the policy stating the reasons why you disagree with terms and conditions of the policy within the free lock-in period as per regulations of IRDAI. For term Insurance policies, the free-look period is of generally 15 days (30 days for online policies) from the date of receiving policy documents.`,
    },
    {
      title:
        "Can I purchase a new term insurance policy even when I already have one?",
      desc: `Yes, you can purchase a new term insurance policy despite already having one. It helps a policyholder get an increased life coverage along with all other benefits of a term insurance plan.`,
    },
    {
      title: "Why is buying term insurance important?",
      desc: `Term insurance policy can help an individual to ensure financial security of their family. In case of your unforeseen demise during the policy tenure, the financial burden of fulfilling financial requirements will move on to your family members who were fully dependent on your income. Under such circumstances, a term insurance policy will provide a death benefit to your family members which will allow them to fulfil their financial requirements in your absence.`,
    },
    {
      title: "Who should Buy term insurance?",
      desc: (
        <>
          Young Professionals: Young professionals who are just starting their
          careers and a lot of them are not married and have no dependents may
          get married and have to financially support their dependent parents
          and kids. It is better to buy a term plan at a young age because after
          buying the plan, the premium amount stays the same throughout the life
          of an individual. The younger the age of the policyholder, the lower
          the term insurance premium will be. Similarly, the older your age is,
          the higher the term plan premium will be.
          <br />
          <br /> Newly Married: A term insurance plan is a financial safety net
          for your spouse even in your absence. In case you are not with your
          life partner, the term insurance policy provides financial coverage to
          the nominee and takes care of their liabilities.
          <br />
          <br /> Working Women: With a life term policy, women can protect the
          financial future of their spouse, children and parents. The payment
          benefit from this plan could help take care of any outstanding loans,
          and debts, or even help their family members meet their future
          financial obligations.
          <br />
          <br /> Taxpayers: Term policy offers tax benefits under section 80C
          which helps taxpayers to lower their tax liabilities.
          <br />
          <br /> Parents: Parents are usually the only financial support for
          their kids. Thus, the best way to secure the financial future of your
          children is to have a term insurance policy. During the policy term, a
          death benefit is paid to the beneficiary of the term insurance plan in
          case of the unfortunate demise of the insured person i.e., parents.
          With this financial safety net of term plan, they never have to worry
          about their children’s dreams.
          <br />
          <br /> Self-Employed People: As a self-employed individual, you don't
          earn a fixed income monthly because of an uneven income source. Along
          with that, you may have also availed of personal or business loans
          from banks and creditors. Buying a term plan to protect your family
          members becomes essential as it ensures that your dependants remain
          financially protected even in your absence.
        </>
      ),
    },
    {
      title: "What are the features of a term insurance plan?",
      desc: (
        <>
          The features of term insurance plans are:
          <br /> High sum assured at affordable premium
          <br /> Protection for your family
          <br /> Can avail tax benefits on premium paid
          <br /> Can enhnace your risk cover by adding riders
          <br /> Protection of liabilities
        </>
      ),
    },
    {
      title: "How to file a term insurance plan claim?",
      desc: (
        <>
          Here is a step-by-step guide on how to file a term insurance claim
          online:
          <br />
          <br /> Step 1: Intimate the Term Insurance Claim Your nominee needs to
          inform the insurance company of the policyholder’s death as soon as
          possible and submit the company’s claims form with the required
          documents. Your nominee can download the claims form online from the
          company’s website.
          <br />
          <br /> Step 2: Assessment of Claim The insurance company will receive
          and assess the authenticity of the claim and check if the documents
          and details provided are accurate or not.
          <br />
          <br /> Step 3: Settlement of Term Insurance Claim Depending on the
          outcome of the assessment the insurance company will either accept or
          reject the term claim. If all the documents and details provided are
          correct, the insurer will approve and transfer the claim amount to the
          nominee’s bank account within 30 days of claim intimation.
        </>
      ),
    },
    {
      title:
        "What are the documents required for term insurance claim process?",
      desc: (
        <>
          Your nominee can file a term insurance claim by submitting the
          following documents:
          <br />
          <br /> Duly filled term insurance company’s claims form (available
          online and offline)
          <br />
          <br /> Medical records like the death/discharge summary, admission
          note, and test results Original term policy documents
          <br />
          <br /> Post-mortem report (if applicable)
          <br />
          <br /> Death Certificate <br />
          <br />
          Photo and ID Proof of the nominee (Voter ID, Adhaar Card, PAN Card)
          <br />
          <br />
          Nominee’s canceled cheque and NEFT mandate form
        </>
      ),
    },
    {
      title: "What is the age limit to buy term plan?",
      desc: `The minimum is 18 years and the maximum age is 65 years.`,
    },
    {
      title:
        "Which documents will I be required to submit while investing in term insurance plans online?",
      desc: (
        <>
          Incase the CKYC journey could not be processed then the following
          documents will be required:
          <br />
          <br /> Photograph
          <br />
          <br /> PAN card <br />
          <br />
          Address & Age Proof such as Driving License (DL), passport, Aadhar
          card, Voter ID, ITR, Form 16 etc.
          <br />
          <br /> ID Proof <br />
          <br />
          Income Proof such as salary Slips for the last 3 months etc. <br />
          <br />
          Medical documents
        </>
      ),
    },
    {
      title:
        "Should I declare myself a tobacco user if I'm an occasional smoker?",
      desc: `It is extremely important that you do not withhold any information mandated by the insurer while investing in term insurance plan. Even if you have smoked a couple of times in the last 12 months, you are obligated to declare yourself as a tobacco user. If this information is later found to be wrongfully withheld, the insurer has the right to consider the policy as null and void, without benefits.`,
    },
    {
      title: "Does term insurance plan cover all kinds of deaths?",
      desc: `Under normal situations, a term policy covers all kinds of death that might fall under Natural or accidental, or illness-related death. Death claims arising out of sexually-transmitted disease, drunk driving, accidents while intoxicated, self-inflicted wounds, murder, death in a war, or while participating in hobbies like skydiving, are not covered by most insurance companies.`,
    },
    {
      title:
        "Do the terms and conditions of a policy change over the policy tenure or with age?",
      desc: `The policy terms and conditions remain the same.

`,
    },
    {
      title: "Is term policy covered under 80D?",
      desc: `You can avail of tax benefits under section 80D of the Income Tax Act, 1961. if you have opted for critical illness rider.`,
    },
    {
      title: "Is ITR mandatory for term insurance?",
      desc: `If you are opting to invest in term insurance, you may be required to provide ITR. However, it is not mandatory in all cases. Insurance companies may insist on ITRs mainly in case the sum assured amount is high i.e., Rs 50 lakhs to Rs. 1 Crore or above.`,
    },
    {
      title: "Do we get return in term insurance plan?",
      desc: `In a regular term plan, there is no return, maturity, or money back at the end of the policy. But in case you have opted for a return of premium term plan, the premiums are paid back, at the end of the policy term as a maturity benefit. This returned amount is subject to some deductions like GST, Admin charges, and other nominal charges.`,
    },
    {
      title: "Who can purchase a term insurance policy under the MWP Act?",
      desc: `If you are currently living in India and a married man, you are eligible to buy a policy under Sec 6 of the MWP Act.`,
    },
  ];

  return (
    <Box className="productWrapper res-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec mt-6" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <Box className="formBox">
              <TermProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12} md={9}>
            <h3 className="mb-2">
              What’s the basic necessity of having a term insurance?
            </h3>
            <p className="mb-4">
              Term Insurance is a necessity because it provides temporary
              financial protection for your loved ones at an affordable price in
              the event of your death.
            </p>

            <h3 className="mb-2">
              How to get the best value from your term insurance?
            </h3>
            <ul className="productpage_listpoints">
              <li>Consider Your Life Stage and Dependents</li>
              <li>Decide your coverage needs</li>
              <li>Compare Quotes</li>
              <li>Determine the length of your term Insurance</li>
              <li>Choose between regular pay & Limited pay</li>
            </ul>
          </Grid>
          <Grid xs={12} md={3} textAlign={"center"}>
            <img
              src="./images/health-insurance-product.svg"
              alt=""
              style={{ maxWidth: "270px", width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">What are the benefits of term insurance?</h3>
            <ol className="productpage_OLpoints">
              <li>
                <span>Affordable Premiums -</span> By making a small premium
                payment, you can obtain high-value security from a term
                insurance plan. Premium payments can be done monthly,
                semi-annually, or annually. The sooner you purchase a term
                insurance policy, the lesser is the premium
              </li>
              <li>
                <span>Whole Life Coverage -</span> Whole life insurance provides
                a guaranteed benefit upon the death of the insured, regardless
                of when they die. This offers a clear advantage over term life
                insurance, which only pays out if the death occurs within a
                specific time frame.
              </li>
              <li>
                <span>Critical Illness Coverage -</span> If your term insurance
                policy includes optional Critical Disease Coverage, you will
                receive a lump sum amount payment upon discovery of any critical
                disease covered by the plan.
              </li>
              <li>
                <span>Accidental Death Benefit -</span> The Accidental Death
                Benefit can be added to your term insurance policy. This will
                provide protection against potential mishaps.
              </li>
              <li>
                <span>Coverage for Terminal Illnesses -</span> In the event of a
                terminal disease or condition which cannot be cured and is
                likely to lead to someone's death, life insurance policies can
                provide lump sum pay-outs
              </li>
              <li>
                <span>Tax Benefits -</span> Tax advantages are available for
                premiums made under Section 80C as well as premiums paid for
                critical sickness benefits under Section 80D. The lump sum
                amount obtained as the sum assured/death benefit by trustees is
                also tax-free under Section 10 (10D) of the Income Tax Act of
                1961.
              </li>
            </ol>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">
              Factors That Affect Term Insurance Premiums
            </h3>
            <ol className="productpage_OLpoints">
              <li>
                <span>Age:</span> The age of the policyholder is one of the most
                significant factor that affects term insurance premiums.
                Generally, the younger you are, the lower your premiums will be.
              </li>
              <li>
                <span>Gender:</span> Women usually have lower term insurance
                premiums than men as they tend to have a longer life expectancy.
              </li>
              <li>
                <span>Health:</span> Your health is another significant factor
                that affects your term insurance premiums. If you are in good
                health, you will have lower premiums than someone with
                pre-existing health conditions.
              </li>
              <li>
                <span>Smoking:</span> If you smoke or use tobacco products, you
                will have higher term insurance premiums as compared to
                non-smokers.
              </li>
              <li>
                <span>Occupation:</span> People with high-risk occupations such
                as pilots or firefighters may have higher premiums.
              </li>
              <li>
                <span>Lifestyle habits:</span> If you engage in risky activities
                such as skydiving, rock climbing, or bungee jumping, you may
                have to pay higher premiums.
              </li>
              <li>
                <span>Coverage amount and duration:</span> For higher coverage
                amounts with longer durations, the premiums also tend to be
                higher.
              </li>
              <li>
                <span>Policy features:</span> Additional policy features, such
                as riders that enhance the coverage, will increase the premium.
                Riders are add-ons or additional benefits which you can opt for
                along with your current life insurance policy at affordable
                rates. Riders are valuable tools that help you in expanding your
                life insurance coverage.
              </li>
              <li>
                <span>Insurance company:</span> Different insurance companies
                have different underwriting processes, and the premiums may vary
                depending on the company.
              </li>
            </ol>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-4">Term Insurance FAQ’s</h3>
            <FAQ data={showAllFaqs ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid xs={12} textAlign={"right"}>
            <CustomButton
              className="primaryBtn large "
              fullWidth={false}
              variant="contained"
              text={showAllFaqs ? "Read Less..." : " Read More..."}
              onClick={() => setShowAllFaqs(!showAllFaqs)}
            />
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermProductPage;
