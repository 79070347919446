import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import "./../../../SCSS/Cms.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";

function Disclaimer() {
  const isMobile = useIsMobile();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box className="cmsWrapper res-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec disclaimer" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h2 style={{ marginBottom: "12px", textAlign: "center" }}>
              DISCLAIMER
            </h2>
          </Grid>
          <Grid xs={12}>
            <p style={{ fontSize: "15px" }}>
              Motilal Oswal Financial Services Ltd (MOFSL) CIN:
              L67190MH2005PLC153397 is a Corporate Agent (Composite) registered
              with IRDAI (Insurance Regulatory and Development Authority of
              India) bearing registration no. CA0579 and having its registered
              office at Motilal Oswal Tower, Rahimtullah Sayani Rd., Opp Parel
              ST Depot, Prabhadevi, Mumbai-400025. MOFSL has tie-ups with
              following Insurance Companies in all 3 category of Life, Health &
              General Insurance.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h6 className="mb-2">Life</h6>
            <ol type="1" className="company_listing_list">
              <li>
                Bajaj Allianz Life Insurance Company Limited.
                <ul className="inner_list">
                  <li>CIN: U66010PN2001PLC015959 </li>
                  <li>IRDAI Reg. No.116 </li>
                  <li>
                    Regd. Office Address: Bajaj Allianz House, Airport Road,
                    Yerawada, Pune - 411006{" "}
                  </li>
                  <li>
                    {" "}
                    Email:{" "}
                    <a
                      href="mailto:customercare@bajajallianz.co.in"
                      target="_blank"
                    >
                      customercare@bajajallianz.co.in{" "}
                    </a>
                  </li>
                  <li>Toll free no: 1800 209 7272</li>
                  <li>Tel : 020-66026773</li>
                </ul>
              </li>
              <li>
                HDFC Life Insurance Company Limited
                <ul className="inner_list">
                  <li>CIN: L65110MH2000PLC128245</li>
                  <li>IRDAI Reg. No. 101</li>
                  <li>
                    Regd. Office: Lodha Excelus, 13th Floor, Apollo Mills
                    Compound, N.M. Joshi Marg, Mahalaxmi, Mumbai 400011
                  </li>
                  <li>
                    Email:{" "}
                    <a href="mailto:service@hdfclife.com" target="_blank">
                      {" "}
                      service@hdfclife.com{" "}
                    </a>{" "}
                  </li>
                  <li>Toll free no.: 1860 267 9999</li>
                  <li>Tel : 022-67516666</li>
                </ul>
              </li>
              <li>
                ICICI Prudential Life Insurance Company Limited
                <ul className="inner_list">
                  <li>CIN: L66010MH2000PLC127837</li>
                  <li>IRDAI Reg. No. 105</li>
                  <li>
                    Regd. Office: ICICI PruLife Towers, 1089 Appasaheb Marathe
                    Marg, Prabhadevi, Mumbai-400025
                  </li>
                  <li>
                    Email:{" "}
                    <a href="mailto:lifeline@iciciprulife.com" target="_blank">
                      {" "}
                      lifeline@iciciprulife.com{" "}
                    </a>
                  </li>
                  <li>Toll free no: 1-860-266-7766</li>
                  <li>Tel: 40391600</li>
                </ul>
              </li>
            </ol>
          </Grid>
          <Grid xs={12}>
            <h6 className="mb-2">Health</h6>
            <ol type="1" className="company_listing_list">
              <li>
                Aditya Birla Health Insurance Company Limited.
                <ul className="inner_list">
                  <li>CIN: U66000MH2015PLC263677</li>
                  <li>IRDAI Reg. No. 153</li>
                  <li>
                    Regd. Office: 9th Floor, One World Centre, Tower1, Jupiter
                    Mill Compound, S. B. Marg, Elphinstone Rd, Mumbai-400013
                  </li>
                  <li>
                    Email:{" "}
                    <a
                      href="mailto:care.healthinsurance@adityabirlacapital.com"
                      target="_blank"
                    >
                      care.healthinsurance@adityabirlacapital.com{" "}
                    </a>{" "}
                  </li>
                  <li>Toll free number - 1800 270 7000</li>
                </ul>
              </li>
              <li>
                Care Health Insurance Company Ltd.
                <ul className="inner_list">
                  <li>CIN: U66000DL2007PLC161503</li>
                  <li>IRDAI Reg. No. 148</li>
                  <li>
                    Regd. Office: 5th Floor, 19, Chawla House, Nehru Place, New
                    Delhi-110019
                  </li>
                  <li>
                    Email:{" "}
                    <a
                      href="mailto:customerfirst@Carehealthinsurance.com"
                      target="_blank"
                    >
                      customerfirst@Carehealthinsurance.com
                    </a>
                  </li>
                  <li>Toll free number 1800 102 4488</li>
                </ul>
              </li>

              <li>
                Star Health & Allied Insurance Co. Ltd.
                <ul className="inner_list">
                  <li>CIN: L66010TN2005PLC056649</li>
                  <li>IRDAI Reg. No. 129</li>
                  <li>
                    Regd. Office: 1 New Tank Street, Vallavur Kottam High Road,
                    Nungambakkam, Chennai 600034.{" "}
                  </li>
                  <li>
                    Email:{" "}
                    <a href="mailto:info@starhealth.in" target="_blank">
                      {" "}
                      info@starhealth.in
                    </a>
                  </li>
                  <li>Toll Free No: 1800 425 2255</li>
                </ul>
              </li>
            </ol>
          </Grid>
          <Grid xs={12}>
            <h6 className="mb-2">General</h6>
            <ol type="1" className="company_listing_list">
              <li>
                Bajaj Allianz General Insurance Company Limited.
                <ul className="inner_list">
                  <li>CIN: U66010PN2000PLC015329</li>
                  <li>IRDAI Reg. No. 113</li>
                  <li>
                    Regd. Office: Bajaj Allianz House, Airport Road, Yerawada,
                    Pune – 411006
                  </li>
                  <li>
                    Email:{" "}
                    <a
                      href="mailto:bagichelp@bajajallianz.co.in"
                      target="_blank"
                    >
                      bagichelp@bajajallianz.co.in
                    </a>
                  </li>
                  <li>Toll free no: 1800 209 5858</li>
                </ul>
              </li>
              <li>
                ICICI LOMBARD GENERAL INSURANCE CO. LTD.
                <ul className="inner_list">
                  <li>CIN: L67200MH2000PLC129408</li>
                  <li>IRDAI Reg. No. 115</li>
                  <li>
                    Regd. Office: ICICI Lombard House, 414, Veer Savarkar Marg,
                    Near Siddhi Vinayak Temple, Prabhadevi, Mumbai - 400025
                  </li>
                  <li>
                    Email:{" "}
                    <a
                      href="mailto:customersupport@icicilombard.com"
                      target="_blank"
                    >
                      customersupport@icicilombard.com
                    </a>
                  </li>
                  <li>Toll free no: 1800 2666</li>
                </ul>
              </li>

              <li>
                HDFC ERGO GENERAL INSURANCE COMPANY LTD.
                <ul className="inner_list">
                  <li>CIN: U66030MH2007PLC177117</li>
                  <li>IRDAI Reg. No.146</li>
                  <li>
                    Regd. Office: 1st Floor, HDFC House, 165-166 Backbay
                    Reclamation, H. T. Parekh Marg, Churchgate, Mumbai – 400 020
                  </li>
                  <li>
                    Email:{" "}
                    <a href="mailto:care@hdfcergo.com" target="_blank">
                      {" "}
                      care@hdfcergo.com
                    </a>
                  </li>
                  <li>Tel: 022 6234 6234</li>
                </ul>
              </li>
            </ol>
          </Grid>
          <Grid xs={12}>
            <h6 className="mb-2">Note:</h6>
            <ul className="list-type">
              <li>
                MOFSL is acting as an agent for distribution of third party
                insurance products and hence MOFSL have no financial liability
                with regards to these insurance policies/plans sold.
              </li>
              <li>
                You may visit our Website:{" "}
                <a
                  href="https://www.motilaloswal.com/insurance"
                  target="_blank"
                >
                  www.motilaloswal.com/insurance.
                </a>
              </li>
              <li>
                The purchase of an insurance plan by the customer is purely on
                voluntary basis.
              </li>
              <li>
                MOFSL Corporate agent does not underwrite the risk or act as an
                insurer.
              </li>
              <li>
                MOFSL Corporate agent only disseminate the requisite information
                in respect of insurance products offered for sale by the
                insurers with whom MOFSL have arrangements.
              </li>
              <li>
                MOFSL Corporate agent only render necessary assistance to the
                policy holders or claimants or beneficiaries in complying with
                the requirements for settlement of claims by the insurer
              </li>
              <li>
                All claims under the policy will be solely decided upon by the
                Insurance Companies.
              </li>
              <li>
                For any queries with respect to Insurance Policies purchased
                through MOFSL, kindly contact us on Customer Support No: 022
                40548000 and Email ID:{" "}
                <a href="mailto:mofslinsurance@motilaloswal.com">
                  mofslinsurance@motilaloswal.com
                </a>{" "}
              </li>
            </ul>

            <div className="beware_warning">
              <p style={{ fontSize: "15px", marginBottom: "8px" }}>
                BEWARE OF SPURIOUS PHONE CALLS AND FICTITIOUS/FRAUDULENTOFFERS:
              </p>
              <ul className="list-type">
                <li>
                  IRDAI is not involved in activities like selling insurance
                  policies, announcing bonus or investment of premiums.
                </li>
                <li>
                  Public receiving such phone calls are requested to lodge a
                  police complaint.
                </li>
              </ul>
            </div>

            <h6 className="mt-4 mb-2">
              MotilalOswal Financial Services Limited (MOFSL)* Member of NSE,
              BSE, MCX, NCDEX CIN No.: L67190MH2005PLC153397
            </h6>
            <p style={{ fontSize: "15px", marginBottom: "8px" }}>
              Registered Office Address: MotilalOswal Tower, RahimtullahSayani
              Road, Opposite Parel ST Depot, Prabhadevi, Mumbai-400025; Board
              line: 022-71934263; Website www.motilaloswal.com. Correspondence
              Office Address: Palm Spring Centre, 2nd Floor, Palm Court Complex,
              New Link Road, Malad (West), Mumbai- 400 064. Tel No: 022 7188
              1000. Registration Nos.: MotilalOswal Financial Services Limited
              (MOFSL)*: INZ000158836. (BSE/NSE/MCX/NCDEX); CDSL and NSDL:
              IN-DP-16-2015; Research Analyst: INH000000412. AMFI: ARN - 146822;
              Investment Adviser: INA000007100; Insurance Corporate Agent:
              CA0579. MotilalOswal Asset Management Company Ltd. (MOAMC): PMS
              (Registration No.: INP000000670); PMS and Mutual Funds are offered
              through MOAMC which is group company of MOFSL. MotilalOswal Wealth
              Management Ltd. (MOWML): PMS (Registration No.: INP000004409) is
              offered through MOWML, which is a group company of MOFSL. •
              MotilalOswal Financial Services Limited is a distributor of Mutual
              Funds, PMS, Fixed Deposit, Bond, NCDs, Insurance Products,
              Investment advisor and IPOs etc. These are not Exchange traded
              products and the Trading Member is just acting as distributor. All
              disputes with respect to the distribution activity would not have
              access to Exchange Investor Redressal Forum or Arbitration
              mechanism • Real Estate is offered through MotilalOswal Real
              Estate Investment Advisors II Pvt. Ltd. which is a group company
              of MOFSL. • Private Equity is offered through MotilalOswal Private
              Equity Investment Advisors Pvt. Ltd which is a group company of
              MOFSL. • Research & Advisory services is backed by proper
              research. Please read the Risk Disclosure Document prescribed by
              the Stock Exchanges carefully before investing. There is no
              assurance or guarantee of the returns. Details of Compliance
              Officer: Name: Neeraj Agarwal, Email ID: na@motilaloswal.com,
              Contact No.:022-71881085.The securities quoted are exemplary and
              are not recommendatory. Brokerage will not exceed SEBI prescribed
              limit. Customer having any query/feedback/ clarification may write
              to query@motilaloswal.com. In case of grievances for Securities
              Broking write to grievances@motilaloswal.com, for DP to
              dpgrievances@motilaloswal.com.
            </p>
            <h6>
              *Such representations are not indicative of future
              results.Investment in securities market are subject to market
              risks, read all the related documents carefully before investing.
            </h6>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Disclaimer;
