import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../Store/Store/hooks";
import { COMMON_ROUTES } from "../../Routing/Path/CommonRoutes";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { COLORS } from "../../SupportingFiles/colors";
import RKTextField from "../../Component/InputFields/RKTextField/RKTextField";
import DatePicker from "../../Component/InputFields/DatePicker/DatePicker";
import CustomButton from "../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import SelectDropdown from "../../Component/InputFields/SelectDropdown/SelectDropdown";
import SearchDropdown from "../../Component/InputFields/SearchDropdown/SearchDropdown";
function TermsProductPage({
  annual_income_data,
  age_data,
  gender_data,
  smokinStatus_data,
  updateMasterState,
  validate_form,
  loader,
  formData,
}: {
  annual_income_data: any;
  age_data: any;
  gender_data: any;
  formData: any;
  updateMasterState: Function;
  loader: any;
  validate_form: Function;
  smokinStatus_data: any;
}) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Term } = useAppSelector((state) => state);
  console.log("formData.age.value", formData.age.value);

  return (
    <Grid
      container
      columnSpacing={3}
      className="row  mb-margin2"
      alignItems={"center"}
    >
      {/* <Grid xs={12} textAlign={"center"} className="mb-margin2">
            {isMobile ? (
              <h2 style={{ marginBottom: "24px" }}>
                <span>Secure your family's future with our </span>
                Term insurance!
              </h2>
            ) : null}
          </Grid> */}
      <Grid xs={12}>
        {/* {isMobile ? null : (
              <h2 style={{ marginBottom: "24px" }}>
                <span>Secure your family's future with our </span>
                Term insurance!
              </h2>
            )} */}
        <Box className="form-box-pp">
          <Grid
            container
            spacing={2}
            textAlign="center"
            justifyContent={"center"}
          >
            <Grid xs={12}>
              <h4 className="mb-2">Find the best Term Insurance</h4>
            </Grid>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField mb-2"
                title={"Annual Income"}
                attrName={"annualIncome"}
                value={`${formData.annualIncome.value}`}
                value_update={updateMasterState}
                data={annual_income_data}
                warn_status={formData.annualIncome.warning}
                error_message={"Select Annual Income"}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField  mb-2"
                title={"Do you smoke?"}
                attrName={"smoke"}
                value={formData.smoke.value}
                value_update={updateMasterState}
                data={smokinStatus_data}
                warn_status={formData.smoke.warning}
                error_message={"Select Smoker"}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField  mb-2"
                title={"Your Gender"}
                attrName={"gender"}
                value={formData.gender.value}
                value_update={updateMasterState}
                data={gender_data}
                warn_status={formData.gender.warning}
                error_message={"Select Gender"}
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField mb-2"
                title={"Date of Birth"}
                value={formData.age.value}
                attrName={"age"}
                value_update={updateMasterState}
                warn_status={formData.age.warning}
                error_message="Select DOB"
                min_date={65}
                max_date={18}
                default_date={
                  formData.age.value === ""
                    ? subYears(new Date(), 18)
                    : subYears(new Date(), 0)
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Pincode"}
                value={formData.pincode.value}
                attrName={"pincode"}
                value_update={updateMasterState}
                warn_status={formData.pincode.warning}
                error_message={
                  isEmpty(formData.pincode.value)
                    ? "Enter Pincode"
                    : "Enter Valid Pincode"
                }
                max_length={6}
                validation_type="NUMBER"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Mobile"}
                value={formData.mobile.value}
                attrName={"mobile"}
                value_update={updateMasterState}
                warn_status={formData.mobile.warning}
                error_message={
                  isEmpty(formData.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number"
                }
                max_length={10}
                validation_type="NUMBER"
              />
            </Grid>

            <Grid xs={12}>
              <CustomButton
                className="primaryBtn"
                text="View Quotes"
                onClick={() => {
                  validate_form();
                }}
                fullWidth={false}
                variant={"text"}
              />
            </Grid>
            <Grid xs={12} className="popup-Checkbox">
              <CustomCheckbox
                isChecked={true}
                // label="By clicking on view quotes you agree to all Terms & Conditions."
                label={
                  <>
                    By clicking on view quotes you agree to all {""}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}
                    >
                      Terms & Conditions
                    </span>
                  </>
                }
                defaultChecked
                value={true}
                disabled={true}
                attrName={"termCondition"}
                // value={Term?.ADD_FORM?.term_cond?.value}
                // defaultChecked={Term?.ADD_FORM?.term_cond?.value}
                // value_update={(a: any, v: any) => {
                //   dispatch(
                //     TermSlice.actions.setTermAddFormData({
                //       ...Term.ADD_FORM,
                //       term_cond: {
                //         value: v,
                //         warning: isEmpty(v),
                //       },
                //     })
                //   );
                // }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default TermsProductPage;
