import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./NavBar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import LoginPopup from "./LoginPopup/LoginPopup";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";

function NavBar() {
  const navigate = useNavigate();
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  // product dropdown
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorE2);
  const producthandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const producthandleClose = () => {
    setAnchorE2(null);
  };
  return (
    <Box className="navbar mb-6">
      <LoginPopup open={openLoginPopup} setOpen={setOpenLoginPopup} />
      <Grid container columnSpacing={3} alignItems={"center"}>
        <Grid xs={4}>
          <ul>
            <li>
              <Button
                className="nav-link product-link"
                id="basic-link"
                aria-controls={open2 ? "basic-menu2" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={producthandleClick}
                disableRipple
                sx={{
                  padding: 0,
                  color: COLORS.black,
                  fontWeight: 400,
                  textTransform: "capitalize",
                  fontSize: "16px",
                  lineHeight: "normal",
                  fontFamily: "Roboto",
                  display: "inline-flex",
                  alignItems: "center",
                  "&:hover": {
                    background: "none",
                    color: COLORS.primary,
                  },
                }}
              >
                Insurance
                <KeyboardArrowDownRoundedIcon sx={{ marginLeft: "4px" }} />
              </Button>
              <Menu
                className="popoverMenu"
                id="basic-menu2"
                anchorEl={anchorE2}
                open={open2}
                onClose={producthandleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-link",
                  sx: {
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontFamily: "jioType",
                  },
                }}
              >
                <MenuItem
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                  Term Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                  Car Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                  Two-Wheeler Insurance
                </MenuItem>
              </Menu>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                About Us
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CLAIMS)}>Claims</Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={4} textAlign={"center"}>
          <img
            src="./images/motilalOswal-logo.svg"
            height="50px"
            alt="Motilal Oswal Insurance"
            onClick={() => navigate(COMMON_ROUTES.HOME)}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid xs={4}>
          <Box>
            <ul style={{ justifyContent: "flex-end" }}>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}>
                  Contact us
                </Link>
              </li>
              <li style={{ width: "106px" }}>
                <CustomButton
                  className={"primaryBtn small"}
                  text="Login"
                  fullWidth={true}
                  variant={"contained"}
                  onClick={() => setOpenLoginPopup(true)}
                />
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NavBar;
