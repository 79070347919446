import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import "./../../../SCSS/Cms.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import FAQ from "../../../Component/FAQ/FAQ";

function FAQs() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useIsMobile();
  const faqs = [
    {
      title: "What is Insurance?",
      desc: `Insurance is a contract which is presented as a policy to be used as a risk
            management tool to
            ensure financial protection at the time of crisis. Insurance helps an individual to
            ensure financial protection against losses that may arise during an unforeseen
            event. An insurance policy is a contract between an individual (policyholder) and an
            insurance company (Insurance provider), under which, the individual makes regular
            payments known as premiums to the insurance company which in return pays the sum
            assured in case an unforeseen event such as demise of the policyholder, accident,
            damage to the vehicles or other possessions.`,
    },
    {
      title: "Why is insurance important?",
      desc: `Unfortunate events like accidents, illnesses, and natural disasters come without any
            warning and
            thus it is necessary for you to keep yourself and your loved ones shielded against
            such unforeseen happenings. One of the best and simplest ways of keeping yourself
            secured against these contingent events which may cause a financial loss is buying
            an insurance policy.`,
    },
    {
      title: "How Does Insurance Work?",
      desc: `As mentioned earlier, insurance is a legal contract between the policyholder and the
            insurance provider.
            The insurance policy carries all the details about the aspects and conditions under
            which the insurance provider will pay out the insurance amount to the policyholder
            or their nominee in case an unforeseen event occurs. Insurance is a financial tool
            which helps in ensuring financial protection of yourself and your family. Generally
            the person who has purchased the policy also known as policyholder has to pay
            premiums for the coverage available under the insurance policy. Any person can seek
            insurance from an insurance company.`,
    },
    {
      title: "What are the types of insurance available?",
      desc: (
        <>
          There are several types of insurance available in India. The four most
          common types of insurance bought in India are as mentioned below :{" "}
          <ol style={{ paddingLeft: "16px", marginTop: "12px" }}>
            <li style={{ marginTop: "8px" }}>
              <span> Motor Insurance :</span> Motor insurance policy is a type
              of insurance policy that provides financial assistance in the
              event of an accident or mishap involving your vehicle. Motor
              insurance can be purchased for three categories of vehicles which
              are personally owned four wheeler, personally owned two wheeler
              (bikes and scooters) and commercial vehicles. There are three
              types of motor insurance available in India which are Third party
              Liability Cover, Comprehensive Cover and Stand Alone Own-Damage
              cover. According to a person’s coverage requirement for their
              vehicle, they can choose a motor insurance that fulfills their
              requirements. Third party motor insurance policy is mandatory in
              India for all vehicles, as per the Motor Vehicles Act.
            </li>
            <li style={{ marginTop: "8px" }}>
              <span> Health Insurance :</span> Health insurance policies provide
              financial assistance to the policyholder in case they need to be
              admitted to the hospital for any kind of treatment. Additionally,
              some health insurance plans also cover the cost of treatment which
              are undertaken at home before the hospitalization or after
              discharge. There are several health insurance plans available in
              India such as Individual Health Insurance, Family Floater Plans,
              Critical Illness Cover, Senior Citizen Health Insurance, Group
              Health Insurance, Maternity Health Insurance and Personal Accident
              Insurance.
            </li>
            <li style={{ marginTop: "8px" }}>
              <span> Life Insurance :</span> Life insurance is an agreement
              between an individual and an insurance company under which the
              insurance company promises to provide a sum assured (death
              benefit) to the family of the life assured in the event of an
              unforeseen death of the life assured. In case of no death, a sum
              assured known as the maturity benefit is provided to the life
              assured at the time of maturity of the policy under selective life
              insurance plans. There are 6 types of life insurance policies
              available in India which are Term Life Insurance, Unit-Linked
              Insurance Plan (ULIPs), Child Protection Plan, Money Back Plans,
              Retirement Plans and Endowment Plans
            </li>
          </ol>
        </>
      ),
    },
    {
      title: "What are the tax benefits on insurance?",
      desc: (
        <>
          {" "}
          <p className="mb-3">
            Apart from providing coverage from the unforeseen financial losses,
            insurance policies also lets a person avail income tax benefits.
            Below mentioned are some tax benefits that one can avail by
            purchasing an insurance policy under the Income Tax Act :{" "}
          </p>
          <ul style={{ marginBottom: "12px" }}>
            <li style={{ marginTop: "8px" }}>
              <span>Section 80C :</span> Under Section 80C of the Income Tax
              Act, premiums paid to purchase life insurance policy qualify for
              tax exemptions for upto Rs. 1.5 Lakh.
            </li>
            <li style={{ marginTop: "8px" }}>
              <span>Section 80D :</span> Under Section 80D of the Income Tax
              Act, premiums paid towards health insurance policy qualifies for
              tax exemptions.
            </li>
            <li style={{ marginTop: "8px" }}>
              <span>Section 10(10D):</span> Benefits under life insurance policy
              that shall be receivable by the life insured or the nominee also
              qualify for tax exemptions under Section 10(10D) of the Income Tax
              Act.
            </li>
          </ul>
          <p>
            The premium paid for health insurance plans qualify for a tax
            deduction of up to Rs. 25,000 under Section 80D of the Income Tax
            Act. These plans also qualify for an additional Rs. 25,000 tax
            deduction for premium paid for parents’ mediclaim policy (Rs. 50,000
            if parents are senior citizens).
          </p>
        </>
      ),
    },
    {
      title: "Key Components of an Insurance Policy",
      desc: (
        <>
          <p className="mb-3">
            Below mentioned are some components to help you understand what is
            insurance and how does it work :{" "}
          </p>
          <ol style={{ paddingLeft: "16px" }}>
            <li style={{ marginTop: "8px" }}>
              <span> Premium Amount :</span> Premium of an insurance policy is
              the decided amount that you need to pay for the insurance
              coverage. It is typically known as regular payments which can be
              made monthly, quarterly, half-yearly or annually. There are
              various factors on which the premium of an insurance policy is
              calculated by the insurance provider. The idea behind that is to
              check whether the insured person is eligible for the type of
              insurance policy they want to purchase.
            </li>
            <li style={{ marginTop: "8px" }}>
              <span> Policy Limit :</span> Policy limit is defined as the
              maximum amount that an insurance provider will pay for the losses
              covered under the insurance policy. This is determined on the
              basis of policy tenure, loss and similar other factors.{" "}
            </li>
            <li style={{ marginTop: "8px" }}>
              <span> Deductibles :</span> Deductibles under an insurance policy
              refers to the amount that the policyholder has to pay before the
              insurance provider settles the claim. Deductibles shall be
              applicable as per the policy’s terms and conditions.
            </li>
          </ol>
        </>
      ),
    },
    {
      title:
        "What are the factors that affect the calculation of a life insurance plan premium?",
      desc: `Factors that determine the premium of Life Insurance Plans include the policyholder’s age, sum assured, gender, lifestyle,
                                    job, medical history, type of policy, tenure of the policy and riders (if any).`,
    },
    {
      title: "Which is the best life insurance plan?",
      desc: `Different life insurance plans have different features and advantages. Thus, the definition of the best
                                    plan varies from individual to individual. The best life insurance plan is the one which best meets your requirements and budget. However, among all the different types of life insurance plans, the most preferred type of life insurance plan is Term Insurance Plan because it provides high coverage at nominal premium.`,
    },
    {
      title:
        "What are the factors which I should consider before buying a life insurance policy?",
      desc: `Before purchasing a life insurance plan you must check your insurance objectives, your income,
                                    your life insurance existing policies (if any), your assets, liabilities, and your expenses.`,
    },
    {
      title:
        "Does tobacco and alcohol consumption affect life insurance premiums?",
      desc: `Yes, if an individual declares that he/she consumes tobacco/alcohol then the premium for a life insurance 
                                    plan increases because of high-risk involved.`,
    },
    {
      title: "Can I return my policy immediately after I purchase it?",
      desc: `You can return the policy stating the reasons why you disagree with terms and conditions of the policy 
                                    within the free-look period as per regulations of IRDAI. For Life Insurance policies, the free-look period is of generally 15 days (30 days for online policies) from the date of receiving policy documents.`,
    },
    {
      title:
        "Can I purchase a new life insurance policy even when I already have one?",
      desc: `Yes, you can purchase a new life insurance policy despite already having one. It helps a policyholder
                                    get an increased life coverage along with all other benefits of a life insurance plan.`,
    },
    {
      title: "Why is buying life insurance important?",
      desc: `Life insurance policy can help an individual to ensure financial security of their family. 
                                    In case of your unforeseen demise during the policy tenure, the financial burden of fulfilling financial requirements will on to to your family members who were fully dependent on your income, under such circumstances a life insurance policy will provide a death benefit to your family members which will allow them to fulfil their financial requirements in your absence.`,
    },
    {
      title: "How to choose the right sum assured under life insurance?",
      desc: "Before you choose a sum assured for your life insurance policy, it is important that you consider a sum assured which is 10 - 15 times your annual income and enough to help your family to maintain a decent lifestyle in your absence. Consider a few factors such as age, current expenses, liabilities, future expenses and number of financial dependents before choosing a sum assured.",
    },
    {
      title: "What is a health insurance plan?",
      desc: `Health insurance policy is an agreement whereby an insurance company agrees to undertake a
                                    guarantee to compensate the insured for medical expenses in case of a medical emergency. A health insurance policy protects the insured for several surgical expenses, critical illnesses, and daycare expenses, for a policy term, for up to the sum insured limit.`,
    },
    {
      title:
        "Can I purchase more than 1 health insurance plan? How are the claims settled in this case?",
      desc: `Yes. You can be covered under more than one health insurance plan. In this case, the claims are 
                                    settled as per the contribution clause, when the claim is higher than the sum insured for one health insurance policy.`,
    },
    {
      title: "Why should I buy a health insurance plan?",
      desc: `A health insurance policy not only protects the insured financially for future, but also offers
                                    relief in the present. Lifestyle habits such as drinking, smoking, or sedentary lifestyle invite health  issues, which can be minor or serious, may be expensive to treat. To stay financially protected in such times, you need a reliable health insurance plan that covers you at all times. Apart from this, buying a health insurance policy also reduces your overall tax liability by allowing you tax deductions on the premium paid, under Section 80D of the Income Tax Act, 1961.`,
    },
    {
      title: "How is health insurance premium determined?",
      desc: (
        <>
          <p className="mb-3">
            Different health insurance plans have different premiums. The
            insurance companies determine premiums after considering various
            factors that are explained below: 
          </p>
          <ol style={{ paddingLeft: "16px" }}>
            <li style={{ marginBottom: "8px" }}>
              <span> Type of Insurance Plan :</span> Your health insurance
              premium is based on the type of plan you choose. If you choose a
              critical illness insurance plan, then the premium will be high. If
              you choose an individual health insurance policy, then the premium
              will be different from that of a family floater plan. To know the
              difference in health insurance premiums, you can use
              InsuranceDekho’s health insurance premium calculator.{" "}
            </li>
            <li style={{ marginBottom: "8px" }}>
              <span> Age of the Insured :</span> With age, you become more prone
              to health issues and are more likely to make health insurance
              claims. Therefore, you are required to pay higher premiums if you
              buy health insurance plans in later stages of life. This is why it
              is recommended to buy health insurance policy when young. 
            </li>
            <li style={{ marginBottom: "8px" }}>
              <span> Policy Term :</span> Most health insurance plans come for a
              period of 1 year, 2 years, or 3 years. Greater the policy term you
              choose, the greater will be your coverage, and hence higher will
              be your health insurance premium, and vice versa. 
            </li>
            <li style={{ marginBottom: "8px" }}>
              <span> Lifestyle Habits :</span> If you drink alcohol or smoke
              regularly, then you may be denied health insurance completely.
              However, there are some insurance companies that cover you for the
              same, for which they charge high premiums. This is because, with
              these lifestyle habits, you are more prone to health issues.
            </li>
            <li style={{ marginBottom: "8px" }}>
              <span> Family Medical History :</span> Before deciding your health
              insurance premium, the insurance company will ask about your
              family medical history. This is because if someone in your family
              has a certain disease that you are also vulnerable to, then the
              insurance company will charge you a higher premium due to
              increased risk.  
            </li>
            <li style={{ marginBottom: "8px" }}>
              <span> Sum Insured :</span> Health insurance plans come with
              different sum insured options that you can choose from as per your
              budget and requirement. The higher the sum insured, the higher the
              medical coverage and hence higher the health insurance premium.
              But it is not recommended to compromise on the sum insured to save
              on premiums. This is because it will not cover you adequately in
              the time of need.   
            </li>
          </ol>
        </>
      ),
    },
    {
      title:
        "Can I avail coverage benefits if I get hospitalised for less than 24 hours?",
      desc: `Yes. Most health insurance plans cover you for medical treatments that do not require hospitalisation 
                                    of at least 24 hours. These are known as daycare procedures. Daycare treatments are performed under local or general anaesthesia in a clinic, hospital, or daycare center. Some daycare treatments that health insurance plans cover you for are chemotherapy, eye surgery, sinusitis, dialysis, angiography, etc.`,
    },
    {
      title:
        "Do health insurance covers robotic surgery and modern treatments?",
      desc: `According to the health regulations issued by the Insurance Regulatory and Development Authority 
                                    of India (IRDAI) in 2019, all insurance companies are required to insure robotic treatments. Therefore, all insurance companies provide it with some sub-limits and policy conditions.`,
    },
    {
      title: "What Is a cumulative bonus in a health insurance plan?",
      desc: `A cumulative bonus in health insurance refers to the financial benefit which you receive as a 
                                    reward for not making any health insurance claims in a policy year.`,
    },
    {
      title:
        "Under what conditions are my policy premium likely to increase at renewal?",
      desc: (
        <>
          <ol style={{ paddingLeft: "16px" }}>
            <li>
              <span>Healthcare Inflation –</span> Inflation in healthcare is
              soaring at a rate of 12% to 18% which comprise the costs of
              medicines, hospital admission costs, medical advancements, etc. It
              leads your insurance companies to increase your sum insured every
              year which increase your premium too during policy renewal.
            </li>
            <li>
              <span>Age of Policyholder –</span> Increase in age can also impact
              your health insurance premium while renewing the health insurance
              policy, especially for those touching 60 just before renewal.
            </li>
            <li>
              <span>Change in Coverage –</span> If you change your health
              insurance policy coverage during the renewal process either by
              adding some add-on covers to your plan or by changing your insurer
              altogether, it may increase your premium.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: "What if I forgot to pay my health insurance premium?",
      desc: `If you could not make the payment for the health insurance premium on time, then your policy can 
                                    be cancelled. After paying the first premium, you will be given a grace period if you do not make the payment for the premium on time. You can renew your health insurance plan by paying the premium within 15 to 30 days of the grace period, which can vary from insurer to insurer, but, if you miss this opportunity too, then it could risk you losing your coverage.`,
    },
    {
      title:
        "What happens to my health insurance plan after a health claim is filed?",
      desc: `After a health insurance claim is filed and settled by the insurance company, then the policy 
                                    coverage tends to be reduced by an amount that has already been released during the settlement. For Instance, if your buy a plan with Rs. 5 lakh policy coverage and make a claim of Rs. 2 lakh, then you can avail of the health insurance of Rs. 3 lakh in the remaining policy year.`,
    },
  ];
  return (
    <Box className="cmsWrapper res-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ textAlign: "center" }}>Frequently Asked Questions</h3>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"50px"}>
          <Grid xs={12}>
            <FAQ data={faqs} />
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default FAQs;
