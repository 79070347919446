import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import FAQ from "../../../Component/FAQ/FAQ";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "./../../../SCSS/ProductPage.scss";
import HealthProductContainer from "../../../Container/HealthProductContainer/HealthProductContainer";

function HealthProductPage() {
  const isMobile = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const defaultShow = 20;
  const [showAllFaqs, setShowAllFaqs] = useState(false);

  const faqs = [
    {
      title: "What are the benefits of buying a long tenure policy upfront?",
      desc: `A discount ranging between 7.5% to 15% shall be offered on premium, in case policy is purchased for 2-year and above with Annual Premium Payment option. However, it differs from company to company.`,
    },
    {
      title: "What is the right age to buy health insurance?",
      desc: `There is no right or wrong age to buy a health insurance policy. However, it is suggested to buy it as early as possible to keep your premium low. The earlier you buy health insurance, the lesser would the premium be as you have a lesser risk of health issues at a young age as compared to someone who is in their mid-50s or 60s as they are more prone to critical illnesses. Therefore, if you get health insurance in your 30s, you will be able to avail maximum insurance benefits that too at a lower premium.`,
    },
    {
      title: "What is the sum insured in health insurance?",
      desc: `Sum insured refers to the maximum amount that the insurance company pays to the policyholder in case a claim is raised due to an illness or accidental injury. It is the maximum claim amount that the insurer will pay under your health policy to cover your incurred medical expenses. It is also referred to as maximum coverage under health insurance.`,
    },
    {
      title: "Can a person have more than one health insurance policy?",
      desc: `Yes, you can buy more than one health insurance policy in India. For example, if you are covered under a corporate health plan then you can get an individual or family floater health insurance policy as well. Similarly, if you already have individual health insurance, you can get another top-up health plan or a senior citizen health insurance plan for your parents.`,
    },
    {
      title:
        "What if I already have a health insurance policy, but just want to increase my sum insured?",
      desc: `If you want to increase the sum insured of your existing health insurance policy, you can do so at the time of policy renewal. In case sum insured enhancement under your ongoing policy is not possible, you can buy a top-up plan or another health policy to extend the scope of coverage.`,
    },
    {
      title: "What is not covered in a health insurance plan?",
      desc: (
        <>
          "The following medical expenses and situations are not covered in a
          health insurance plan:
          <br />
          <br />
          Unless there is an accidental emergency, claims arising during the
          initial 30 days of buying a health insurance plan are not covered.
          <br />
          Coverage of pre-existing diseases is subject to a waiting period of 2
          to 4 years
          <br />
          Critical illnesses coverage usually comes with 90 days waiting period
          <br />
          Injuries caused by war/terrorism/ nuclear activity
          <br />
          Self-inflicted injuries or suicide attempts
          <br />
          Terminal illnesses, AIDS, and other diseases of similar nature
          <br />
          Cosmetic/plastic surgery, replacement of hormones surgery, etc.
          <br />
          Dental treatment expenses
          <br />
          Treatment/diagnostic tests and post-care procedures without
          hospitalisation
          <br />
          Claims arising out of adventure sports injuries
          <br />
          *Refer policy document for detailed list ."
        </>
      ),
    },
    {
      title: "How much health insurance coverage do I need?",
      desc: `You need to decide the medical insurance coverage according to your lifestyle, pre-existing health conditions, medical background of your family, annual income, age, health risks and the premium that you can afford to pay.`,
    },
    {
      title:
        "Do health insurance plans cover diagnostic charges like X-ray, ultrasound or MRI?",
      desc: `Health insurance plans cover diagnostic charges like X-ray, ultrasound, blood tests or MRI for day care procedures, in patient treatments & for pre and post hospitalisation. Any diagnostic test which does not lead to a treatment or has been prescribed to outpatients are not covered.`,
    },
    {
      title: "Will I get coverage for pre-existing diseases?",
      desc: `Yes. Most health insurance plans provide coverage for pre-existing diseases. However, they are covered only after a waiting period of 2 to 4 consecutive years. You must check your policy documents carefully to know about the waiting period for pre-existing diseases.`,
    },
    {
      title: "Do health insurance covers robotic surgery & modern treatments?",
      desc: `Yes. Several health insurance plans in India cover the cost of robotic surgery and modern treatments. However, you are advised to go through the policy wordings to check if it covers the same.`,
    },
    {
      title:
        "Can I avail coverage benefits if I get hospitalised for less than 24 hours?",
      desc: `Yes. Most health insurance plans cover you for medical treatments that do not require hospitalisation of at least 24 hours. These are known as daycare procedures. Daycare treatments are performed under local or general anaesthesia in a clinic, hospital, or daycare center. Some daycare treatments that health insurance plans cover you for are chemotherapy, eye surgery, sinusitis, dialysis, angiography, etc.`,
    },
    {
      title:
        "What happens to my health insurance plan after a health claim is filed?",
      desc: `After a health insurance claim is filed and settled by the insurance company, then the policy coverage tends to be reduced by an amount that has already been released during the settlement. For Instance, if your buy a plan with Rs. 10 lakh policy coverage and make a claim of Rs. 5 lakh, then you can avail of the health insurance of Rs. 5 lakh in the remaining policy period. However, this is not applicable to Sum insured recharge plans and Sum insured re-instatement policies`,
    },
    {
      title: "What is a cumulative bonus in a health insurance plan?",
      desc: `A cumulative bonus in health insurance policy implies monetary benefits in form of discount on premium or additional sum insured enhancement for every claim free year. It is also called a No Claim Bonus. However, the policy benefits differ from one health insurance company to another.`,
    },
    {
      title: "How does smoking affect health insurance premiums?",
      desc: `The cost of getting a health insurance plan can be significantly higher for those who are regular smokers or tobacco users as smoking predisposes an individual to various diseases like heart complications, hypertension, respiratory issues, cancer, etc.`,
    },
    {
      title:
        "Under what conditions is my policy premium likely to increase at renewal?",
      desc: (
        <>
          "There are several reasons why your health insurance premiums can
          increase during renewal. They are:
          <br />
          <br /> Increase in you and your dependants age.
          <br /> Claims raised in the previous year.
          <br /> Alteration in coverage benefits.
          <br /> Recent diagnosis of a disease.
          <br /> Policy lapse. <br />
          Medical inflation.
        </>
      ),
    },
    {
      title: "How is health insurance premium determined?",
      desc: (
        <>
          "Different health insurance plans have different premiums. The
          insurance companies determine premiums after considering various
          factors that are explained below:
          <br />
          <br /> 1. Type of Insurance Plan: Your health insurance premium is
          based on the type of plan you choose. If you choose a critical illness
          insurance plan, then the premium will be high. If you choose an
          individual health insurance policy, then the premium will be different
          from that of a family floater plan.
          <br />
          <br /> 2. Age of the Insured: With age, you become more prone to
          health issues and are more likely to make health insurance claims.
          Therefore, you are required to pay higher premiums if you buy health
          insurance plans in later stages of life. This is why it is recommended
          to buy health insurance policy when young.
          <br />
          <br /> 3. Policy Term: Most health insurance plans come for a period
          of 1 year, 2 years, or 3 years. Greater the policy term you choose,
          the greater will be your coverage, and hence higher will be your
          health insurance premium, and vice versa.
          <br />
          <br /> 4. Lifestyle Habits: Lifestyle habits like consuming alcohol,
          using tobacco in any form may predispose an individual to various
          diseases like heart complications, hypertension, respiratory issues,
          cancer, etc. which may lead to higher premiums or in some cases
          company can deny the policy. However, there are some insurance
          companies that cover you for the same, for which they charge high
          premiums.
          <br />
          <br /> 5. Family Medical History: Before deciding your health
          insurance premium, the insurance company will ask about your family
          medical history. This is because if someone in your family has a
          certain disease that you are also vulnerable to, then the insurance
          company will charge you a higher premium due to increased risk.
          <br />
          <br /> 6. Sum Insured: Health insurance plans come with different sum
          insured options that you can choose from as per your budget and
          requirement. The higher the sum insured, the higher the medical
          coverage and hence higher the health insurance premium. But it is not
          recommended to compromise on the sum insured to save on premiums. This
          is because it will not cover you adequately in the time of need."
        </>
      ),
    },
    {
      title: "What if I forgot to pay my health insurance premium?",
      desc: `If you could not make the payment for the health insurance premium on time, then your policy can be cancelled. After paying the first premium, you will be given a grace period if you do not make the payment for the premium on time. You can renew your health insurance plan by paying the premium within 15 to 30 days of the grace period, which can vary from insurer to insurer, but, if you miss this opportunity too, then it could risk you losing your coverage.`,
    },
    {
      title: "What if I miss the health insurance policy premium renewal date?",
      desc: `If you miss the renewal date of your health insurance, your policy will get expired. Your insurer will not be legally liable to cover your medical expenses in case of an expired policy. As a result, you will have to pay for your medical expenses on your own unless your policy is renewed.`,
    },
    {
      title: "Why should you avoid policy renewal during the grace period?",
      desc: `You should avoid renewing your health insurance policy during the grace period as your insurer will not provide coverage during this period. As a consequence, you will have to pay for your medical expenditure from your own pockets in case of an illness or an injury. But if you renew your policy before the due date, you will get continuous coverage from your insurer at all times.`,
    },
    {
      title:
        "Do I get a discount on the renewal of the policy with the same health insurance company?",
      desc: `You may get a discount on your health insurance premium in the form of a No Claim Bonus if you renew your policy with the same insurer, provided you had not raised a claim during the previous policy year. You can also avail long term discount and family discount on your premium if you opt for long term policy tenure or include your family members under the same policy respectively.`,
    },
    {
      title:
        "Can a health insurance policy expire if it is not renewed on time?",
      desc: `Yes. Your health insurance policy will expire if you do not renew it on time. An expired policy will not cover you against medical emergencies forcing you to pay for your expenses on your own. Hence, you must ensure to renew your policy before the expiry date and ensure continued coverage.`,
    },
    {
      title: "Can I increase my health insurance cover during renewal?",
      desc: `Yes. You can increase your health insurance coverage at the time of renewing your policy.`,
    },
    {
      title: "Is there a grace period for health insurance renewal?",
      desc: `Yes, all health insurance plans come with a grace period of 30 days for policy renewal. In case you are unable to renew your policy before the policy due date, you can renew it during the grace period. If you do not renew your policy even during the grace period, your policy will lapse.`,
    },
    {
      title:
        "Can I transfer my health insurance policy without losing renewal benefits?",
      desc: `While transferring/porting your health insurance policy from one insurance company to another, you don't lose the benefits that you have accumulated during the policy term. As per IRDA new regulations, the benefits remain intact. Earlier it resulted in losing out on benefits accumulated in health insurance policies, like the waiting period for covering Pre-existing Illnesses.`,
    },
    {
      title: "What happens if my medical policy lapses during hospitalization?",
      desc: `If the policy lapses during hospitalization you won't be able to avail the insurance benefits. Therefore, it is recommended to renew your policy timely if you want to avail continuous policy coverage benefits.`,
    },
    {
      title:
        "If I increase my sum insured during policy renewal, will a waiting period apply?",
      desc: `If you increase your sum insured at the time of renewing your health insurance policy, your insurer may apply a fresh waiting period depending on the policy terms. It is best to check with your insurer if a fresh waiting period will be applicable in case of sum insured enhancement.`,
    },
    {
      title: "Benefits of buying health insurance plans online?",
      desc: (
        <>
          "Buying a health insurance policy online comes with several benefits.
          Take a look at them below:
          <br />
          <br /> Easier to Compare Plans – It is easier to compare health
          insurance plans from different insurers online & make an informed
          decision.
          <br />
          <br /> More Convenient – It is more convenient to buy the policy
          online as you do not have to visit the branch of the insurance company
          or take an appointment to meet an insurance agent.
          <br />
          <br /> Online Discounts – It allows you to avail discount on premiums
          for buying the policy online.
          <br />
          <br /> Lower Premiums – Health plans are available for a lower premium
          online as insurance companies save a lot on operational costs.
          <br />
          <br /> Minimal Paperwork – The process of buying a health insurance
          policy online involves minimum to zero paperwork.
          <br />
          <br /> Policy Available 24x7 – A health insurance policy can be
          purchased online any time of the day, even on public holidays, which
          is not possible in offline buying.
          <br />
          <br /> Digital Payment Options – It allows you to avoid cash payments
          and use digital payments methods to pay the premium online safely.
          <br />
          <br />
          Instant Policy Purchase – A health insurance policy is issued
          instantly when purchased online unlike offline buying.
          <br />
          <br /> Time-saving – It saves you a lot of time as the policy is
          issued within a few minutes of buying."
        </>
      ),
    },
    {
      title: "Eligibility criteria to buy a health insurance plan",
      desc: (
        <>
          "The eligibility criteria to buy a health insurance plan depends on a
          number of factors such as the age of the policyholder, pre-existing
          diseases, etc. In most health insurance plans, the following
          eligibility criteria should be met:
          <br />
          <br /> Age Criteria- The entry age criteria for adults and children
          varies and can range from 18-65 years and 90 days to 25 years
          respectively. The actual age can vary from one medical insurance
          policy to another.
          <br />
          <br /> Pre-medical Screening- Pre-medical examination is required for
          applicants mostly above the age of 45 years or 55 years. However, most
          of the senior citizen health plans require pre-medical tests before
          policy issuance.
          <br />
          <br /> Pre-existing Diseases- Any pre-existing illness is covered
          after the completion of the waiting period i.e. 2-4 years. Most health
          insurers ask the applicant if they are going through any medical
          conditions like blood pressure, diabetes, cardiovascular diseases,
          kidney problems, etc. at the time of buying a health insurance plan.
          If you are a smoker or an alcoholic, then you need to disclose it to
          the insurance company.
          <br />
          <br /> However, this is only indication for the standard health
          insurance covers"
        </>
      ),
    },
    {
      title: "Documents required for health insurance claim reimbursement",
      desc: (
        <>
          "In the event of a hospitalization, the policyholder needs to submit
          certain documents as mentioned below:
          <br />
          <br /> Discharge card issued by the hospital/network hospital <br />
          <br />
          In-patient hospitalization bills signed by insured for authenticity
          <br />
          <br />
          Doctors’ prescriptions and medical store bills Claim-form with
          insured’s signature on it <br />
          <br /> Valid investigation report <br />
          <br /> Consumables and disposables prescribed by the doctors with
          complete details <br />
          <br /> Bills of doctors’ consultation <br />
          <br /> Inpatient report Copies of the <br />
          <br /> Insurance policy from the previous year and the current
          year/copy of ID Card of TPA <br />
          <br /> Any other document(s) asked by the TPA <br />
          <br />
          However, this is only indication for the standard documents that can
          vary Insurer to Insurer in case of claim"
        </>
      ),
    },
    {
      title: "Is a medical test mandatory to buy a health insurance policy?",
      desc: `Medical tests are not mandatory before buying a health insurance policy. However, most health insurance companies in India require medical test reports if the age of the applicants is above 45 years. The type of medical tests required can vary depending on the age of the applicant and the insurer’s requirement.`,
    },
    {
      title:
        "What does cashless hospitalization mean in a health insurance policy?",
      desc: `Cashless hospitalization means that the in-patient treatment charges availed by the insured are paid by the insurance company directly to the hospital. All insurance companies in India have a tie-up with a large network of hospitals where the insured/policyholder can avail cashless treatment for an illness or accidental treatment.`,
    },
    {
      title:
        "At what age can I include my children in my health insurance plan?",
      desc: `You can include your children in a family floater policy from day 1. In some health plans, children are covered from 91 days onwards. Nonetheless, you are advised to go through the terms and conditions of a health plan carefully to know about the entry age for children.`,
    },
    {
      title: "What are pre-existing diseases or conditions?",
      desc: `Any health problems or illnesses diagnosed prior to buying a health insurance policy is called pre-existing diseases. Insurance companies are reluctant to cover such diseases as it is a costly affair for them. Therefore, pre-existing diseases are covered mostly after a waiting period of 2 to 4 years. Besides, every insurance company has its own terms & conditions regarding such illnesses. While some firms prefer to check a person’s entire medical history to know pre-existing condition status, other insurers look for medical records over the past four years`,
    },
    {
      title:
        "I have my employer's group policy; do I need to buy a separate health insurance plan?",
      desc: `Yes, you must buy a separate health insurance policy in addition to your employer’s health insurance policy for better coverage. The sum insured under an employer’s health insurance is usually between Rs 2 lakh and Rs 5 lakh, which might not be sufficient under the current medical inflation. To cover the various expensive treatment costs, it is important to have a separate health insurance plan of a minimum of Rs 10 lakh. Also, insurance cover provided by the company will only last until you are a part of that organisation.`,
    },
    {
      title: "How to add my family members to my existing medical policy?",
      desc: `You can add your family members to your health insurance policy at the time of renewal or at the time of purchase. You, your spouse, dependent children, parents and parents-in-law can be covered in a family health insurance plan as per its terms & conditions.`,
    },
    {
      title:
        "What are the documents required for purchasing a health insurance policy?",
      desc: `Yes, basic KYC documents are required while purchasing a health insurance policy.`,
    },
    {
      title:
        "Can my friend buy a health insurance policy if he/she is not an Indian national but living in India?",
      desc: `Yes, foreigners living in India can apply for a health insurance policy. However, the coverage will be applicable within India only. However, this depends from insurer to insurer.`,
    },
    {
      title:
        "What are pre and post-hospitalization expenses in health insurance?",
      desc: `Pre-hospitalization expenses refer to the medical expenses incurred before getting admitted to a hospital. Post-hospitalization expenses refer to the cost of follow-up tests and consultation treatment charges incurred after getting discharged from the hospital. Health plans in India mostly cover pre-hospitalization expenses up to 30 to 60 days and post-hospitalization expenses up to 60 to 90 days, depending on the plan.`,
    },
    {
      title: "What is recharge/restore facility in health policy?",
      desc: `Recharge and restore benefits are similar concepts in health insurance, however, they provide different benefits. You can restore the sum insured when it gets exhausted and ask for a recharge when it is reduced due to a claim`,
    },
  ];

  const today = new Date();
  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(today.getDate() - 90);
  console.log(today.getDate());
  console.log(ninetyDaysAgo.getDate());

  return (
    <Box className="productWrapper res-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec mt-6" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <Box className="formBox">
              <HealthProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12} md={9}>
            <h3 className="mb-2">
              Why should health insurance be a necessity and not a preference?
            </h3>
            <p className="mb-4">
              Health insurance is an important investment that protects you from
              uncertainties. It keeps your wealth in check while also preparing
              you for sudden and costly medical expenses.
            </p>
            <p className="mb-4">
              Factors that prove health insurance is a necessity and not an
              option:
            </p>
            <ul className="productpage_listpoints">
              <li>Hectic Lifestyles</li>
              <li>Rising cost of treatment</li>
              <li>Life-threatening diseases on the rise</li>
              <li>Peace of mind</li>
              <li>Tax benefits.</li>
            </ul>
          </Grid>
          <Grid xs={12} md={3} textAlign={"center"}>
            <img
              src="./images/health-insurance-product.svg"
              alt=""
              style={{ maxWidth: "270px", width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12} md={9}>
            <h3 className="mb-2">Key Benefits of having a health insurance</h3>
            <ol className="productpage_OLpoints">
              <li>
                <span>Cashless Treatments -</span> If you are hospitalised in a
                listed network hospital, you do not need to worry about finding
                funds to pay your hospital expenses since your insurance will
                cover them under cashless claims.
              </li>
              <li>
                <span>Hospitalization Expenses -</span> The health costs
                incurred after being admitted to the hospital for more than 24
                hours are covered by a health insurance plan. It includes room
                rent, doctor’s fee, medicine cost, diagnostic test fees, etc.
                based on your policy coverage.
              </li>
              <li>
                <span>Health Check-ups -</span> You can avail Free Health
                Check-ups available in your Health Plan
              </li>
              <li>
                <span>Pre & Post Hospitalization Expenses -</span> Your
                insurance pays for any health costs you may have incurred for a
                condition before being admitted to the hospital. It also covers
                any further costs of treatment after being discharged. Your
                policy document specifies a maximum number of days for which
                pre- and post-hospitalization costs are covered.
              </li>
              <li>
                <span>ICU Charges -</span> The expense of receiving care in an
                ICU while hospitalised is also covered by a health insurance
                plan based on your policy coverage
              </li>
              <li>
                <span>Day Care Procedures -</span> It also covers the expense of
                receiving day care therapy that needs less than 24 hours of
                hospitalisation.
              </li>
              <li>
                <span>Ambulance Cost -</span> In the event of a health
                emergency, it pays for the cost of the ambulance services used
                to get to the closest hospital.
              </li>
              <li>
                <span>Pre-existing Diseases -</span> After you have completed
                the waiting time, your health insurance policy will cover
                pre-existing disorders. These conditions are usually covered
                after a 2 to 4 year waiting period depending on the health
                insurance policy you have selected.
              </li>
              <li>
                <span>AYUSH Treatment -</span> It covers the expense of health
                care provided by the AYUSH school of medicine, which includes
                Ayurveda, Unani, Homeopathy & Siddha.
              </li>
            </ol>
          </Grid>
          <Grid xs={12} md={3} textAlign={"center"}>
            <img
              src="./images/before-buying-health-insurance.svg"
              alt=""
              style={{ maxWidth: "270px", width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">Health Insurance Checklist</h3>
            <ul className="productpage_listpoints">
              <li>Network hospitals</li>
              <li>Pre & Post Hospitalisation coverage</li>
              <li>Waiting Period</li>
              <li>No. of day care procedures</li>
              <li>Economic Premiums</li>
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-4">Health Insurance FAQ’s</h3>
            <FAQ data={showAllFaqs ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid xs={12} textAlign={"right"}>
            <CustomButton
              className="primaryBtn large "
              fullWidth={false}
              variant="contained"
              text={showAllFaqs ? "Read Less..." : " Read More..."}
              onClick={() => setShowAllFaqs(!showAllFaqs)}
            />
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default HealthProductPage;
