import { GetAPI } from "../../Axios/AxiosInstance";
import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { CommonURLs } from "../../Routing/CommonURLs/CommonURLs";


const Submit_claim = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    CommonURLs.SUBMIT_CLAIM,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};


const SUBMIT_CONTACT_US = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    CommonURLs.CONTACT_US,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};



const SUBMIT_COMPLAINT = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    CommonURLs.SUBMIT_COMPLAINT,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};





export const HOME_SERVICES = {
  Submit_claim,
  SUBMIT_CONTACT_US,
  SUBMIT_COMPLAINT
};
