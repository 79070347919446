import { Box, Link, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../../SupportingFiles/colors";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";

function MLoginPopup({ open, setOpen }: { open: boolean; setOpen: any }) {
  const [loginStep, setLoginStep] = useState<"1" | "2">("1");

  const [formData, setFormData] = useState<{
    mobile: { value: string; warning: boolean };
    otp: { value: string; warning: boolean };
  }>({
    mobile: { value: "", warning: false },
    otp: { value: "", warning: false },
  });

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "#ffffff",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "74px",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          {/* topnav */}
          <Box
            sx={{
              // backgroundColor: "rgba(255, 243, 244, 1)",
              height: "50px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "9999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid xs={12} paddingLeft={"40px"} paddingRight={"40px"}>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setLoginStep("1");
                  }}
                  sx={{
                    position: "absolute",
                    right: "12px",
                    top: "19px",
                    maxWidth: "30px",
                    minWidth: "30px",
                    maxHeight: "30px",
                    color: COLORS.primary,
                    borderRadius: "0",
                    border: `1px dashed ${COLORS.primary}`,
                    transition: "all ease-in-out 0.2s",
                    "&:hover": {
                      backgroundColor: COLORS.darkGrey,
                      transform: "rotate(-90deg)",
                    },
                  }}
                >
                  <CloseIcon style={{ height: "24px", width: "24px" }} />
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* contentsec */}
          <Box>
            <Grid container columnSpacing={3} className="row content-sec">
              <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
                <img
                  src="./images/login.svg"
                  alt="login-img"
                  height={"200px"}
                />
              </Grid>
            </Grid>
            {loginStep === "1" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12} textAlign={"center"}>
                  <h3 style={{ fontSize: "28px", marginBottom: "8px" }}>
                    Welcome
                  </h3>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 400,
                      marginBottom: "24px",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    Login to your Account
                  </p>
                </Grid>
                <Grid xs={8}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter mobile number"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={"Enter mobile number"}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn large"
                    text="Send OTP"
                    onClick={() => setLoginStep("2")}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            ) : loginStep === "2" ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid xs={12} textAlign={"center"}>
                  <h3 style={{ fontSize: "28px", marginBottom: "8px" }}>
                    One Time Password
                  </h3>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 400,
                      marginBottom: "24px",
                      fontSize: "15px",
                      lineHeight: "18px",
                    }}
                  >
                    sent successfully on{"  "}
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {"  "}+91-9876543210
                    </span>
                    <Link
                      sx={{
                        fontSize: "13px",
                        padding: "2px 14px",
                        borderRadius: "8px",
                        color: COLORS.secondary,
                        border: `1px solid ${COLORS.secondary}`,
                        fontWeight: 500,
                        marginLeft: "12px",
                        display: "inline-block",
                        lineHeight: "18px",
                      }}
                      onClick={() => setLoginStep("1")}
                    >
                      Edit
                    </Link>
                  </p>
                </Grid>
                <Grid xs={8}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter OTP"}
                    value={formData.otp.value}
                    attrName={"otp"}
                    value_update={updateMasterState}
                    warn_status={formData.otp.warning}
                    error_message={"Enter OTP"}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn large"
                    text="Verify OTP"
                    onClick={() => {}}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12}>
                  <p style={{ textAlign: "center" }}>
                    Didn’t receive?{" "}
                    <Link
                      sx={{
                        fontSize: "13px",
                        padding: "2px 14px",
                        borderRadius: "8px",
                        color: COLORS.secondary,
                        border: `1px solid ${COLORS.secondary}`,
                        fontWeight: 500,
                        marginLeft: "12px",
                        display: "inline-block",
                        lineHeight: "18px",
                      }}
                    >
                      Resend
                    </Link>
                  </p>
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MLoginPopup;
