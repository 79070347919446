import { BrowserRouter } from "react-router-dom";
import CommonRoutes from "./Routing/Routes/CommonRoutes";
import MyAccount from "./Page/Desktop/MyAccount/MyAccount";
import MMyAccount from "./Page/Mobile/MMyAccount/MMyAccount";

function App() {
  return (
    <BrowserRouter>
      <CommonRoutes />
    </BrowserRouter>
  );
}

export default App;
