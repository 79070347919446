import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MAboutUs from "../../Page/Mobile/MAboutUs/MAboutUs";
import AboutUs from "../../Page/Desktop/AboutUs/AboutUs";

const AboutUsContainer = () => {
  const isMobile = useIsMobile();

  return isMobile ? <MAboutUs /> : <AboutUs />;
};

export default AboutUsContainer;
