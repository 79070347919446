import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import "./../../../SCSS/Cms.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";

function CancellationRefund() {
  const isMobile = useIsMobile();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box className="cmsWrapper res-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec pp" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h2 style={{ marginBottom: "12px", textAlign: "center" }}>
              Cancellation and Refund
            </h2>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"50px"}>
          <Grid xs={12}>
            <h5 style={{ marginBottom: "8px" }}>Cancellation Policy</h5>
          </Grid>
          <Grid xs={12}>
            <p>
              In case of a cancellation, the premium paid for an insurance
              product will be refunded through direct credit or cheque as per
              the policy of the respective Insurance Company that you have
              chosen to buy the product from.
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" marginBottom={"80px"}>
          <Grid xs={12}>
            <h5 style={{ marginBottom: "8px" }}>Refund Policy</h5>
          </Grid>
          <Grid xs={12}>
            <p>
              In case of a refund due to an erroneous transaction or
              cancellation, the premium paid for an insurance product will be
              refunded through direct credit or cheque as per the prevailing
              policy of the respective Insurance Company that you have chosen to
              buy the product from.
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default CancellationRefund;
