import { Box, CircularProgress } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./Store/Store/Store";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate
          loading={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          persistor={persistor}>
          <ToastContainer theme="colored" />
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  reportWebVitals();
}

renderApp();
