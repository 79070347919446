import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import "./../../../SCSS/Cms.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import FAQ from "../../../Component/FAQ/FAQ";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import {
  isEmpty,
  validateFullName,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import { toast } from "react-toastify";
import { HOME_SERVICES } from "../../../Services/Home/HomeServices";
function Contact() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useIsMobile();

  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
  });

  console.log("frgdfg", formFields);

  const updateFieldsState = (attrName: string, value: string) => {
    if (attrName === "mobile") {
      setFormFields((prev: any) => ({
        ...prev,
        mobile: { value: value, warn_status: !validateMobileNumber(value) },
      }));
    } else if (attrName === "name") {
      setFormFields((prev) => ({
        ...prev,
        name: { value: value, warn_status: !validateFullName(value) },
      }));
    }
  };

  const CONTACT_US = () => {
    const toastID = toast.loading("Please wait...");
    let params = {
      username: formFields.name.value,
      number: formFields.mobile.value,
    };
    const onSuccess = (data: any) => {
      console.log(data);
      toast.dismiss(toastID);
      const results = data.status;
      const error = results;
      if (error) {
        toast.error("Something went wrong.");
      } else {
        setFormFields({
          name: { value: "", warn_status: false },
          mobile: { value: "", warn_status: false },
        });
        toast.success("Thank you for Contacting Us.");
      }
    };
    const onError = () => {
      toast.dismiss(toastID);
    };
    HOME_SERVICES.SUBMIT_CONTACT_US(onSuccess, onError, params);
  };

  const validateForm = () => {
    let data = { ...formFields };
    data = {
      ...data,
      name: {
        ...data.name,
        warn_status: !validateFullName(data.name.value),
      },
      mobile: {
        ...data.mobile,
        warn_status: !validateMobileNumber(data.mobile.value),
      },
    };
    setFormFields(data);
    if (data.name.warn_status === false && data.mobile.warn_status === false) {
      CONTACT_US();
    }
  };

  return (
    <Box className="cmsWrapper res-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h2 style={{ textAlign: "center", marginBottom: "8px" }}>
              We would love to hear from you
            </h2>
            <p style={{ textAlign: "center" }}>
              Fill the form below and our team will get in touch with you
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row"
          marginBottom={"50px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid xs={12} marginBottom={"50px"}>
            <Box className="contactBox">
              <Grid container columnSpacing={3}>
                <Grid xs={12} md={6}>
                  <Box className="callCard" marginLeft={"auto"}>
                    <Box textAlign={"left"}>
                      <h6>You can speak to an expert right now:</h6>
                      <p>
                        Helpline No. :{" "}
                        <a href="tel:+022 40548000">022 40548000</a>
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} md={6}>
                  <Box className="mailCard">
                    <Box className="mailcardInner">
                      <h6>Write us on the e-mails given :</h6>
                      <p>
                        <a href={`mailto:mofslinsurance@motilaloswal.com`}>
                          mofslinsurance@motilaloswal.com
                        </a>
                        {/* <p>
                        <a href="mail:mofslinsurance@motilaloswal.com">
                          mofslinsurance@motilaloswal.com
                        </a> */}
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box className="addressCard">
                    <Box className="mailcardInner">
                      <h6>Come & Say Hi to us ,here </h6>
                      <p>
                        <span>
                          Motilal Oswal Tower, Rahimtullah Sayani Road, Opposite
                          Parel ST Depot, Prabhadevi, Mumbai-400025
                        </span>
                      </p>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid xs={12} md={8}>
            <Box className="contactFields">
              <Grid container columnSpacing={3} className="row">
                <Grid xs={12}>
                  <h4 style={{ marginBottom: "20px" }}>
                    Contacting us is EASY
                  </h4>
                </Grid>
                <Grid xs={12} md={6}>
                  <RKTextField
                    class_name="inputField mb-5"
                    title={"Name"}
                    value={formFields.name.value}
                    attrName={"name"}
                    value_update={updateFieldsState}
                    warn_status={formFields.name.warn_status}
                    validation_type="NAME"
                    error_message={
                      isEmpty(formFields.name.value)
                        ? "Enter Name"
                        : "Enter Full Name"
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <RKTextField
                    class_name="inputField mb-5"
                    title={"Mobile"}
                    value={formFields.mobile.value}
                    attrName={"mobile"}
                    value_update={updateFieldsState}
                    warn_status={formFields.mobile.warn_status}
                    error_message={
                      isEmpty(formFields.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    max_length={10}
                    validation_type="NUMBER"
                  />
                </Grid>
                <Grid xs={12}>
                  <CustomButton
                    className="primaryBtn large mb-1"
                    fullWidth={false}
                    variant="contained"
                    text="Call me back"
                    onClick={() => {
                      validateForm();
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Contact;
