import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import "./../../../SCSS/Cms.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";

function PrivacyPolicy() {
  const isMobile = useIsMobile();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box className="cmsWrapper res-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec pp" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h2 style={{ marginBottom: "12px", textAlign: "center" }}>
              PRIVACY POLICY
            </h2>
          </Grid>
          <Grid xs={12}>
            <p className="mb-2" style={{ color: "#000000B3" }}>
              <span>
                {" "}
                Declaration (Mandatory Declarations before submission of
                Proposer Data)
              </span>{" "}
              None of the Insured members fall in the category of Politically
              Exposed Persons (P.E.P.)
            </p>
            <p>
              I / We hereby declare, on my behalf and on behalf of all persons
              proposed to be insured, that the above statements, answers and /
              or particulars given by me are true and complete in all respects
              to the best of my knowledge and that I / We am / are authorized to
              propose on behalf of these other persons.
            </p>
            <p>
              I understand that the information provided by me will form the
              basis of the insurance policy, is subject to the Board approved
              underwriting policy of the insurance company and that the policy
              will come into force only after full receipt of the premium
              chargeable.
            </p>
            <p>
              I / We declare and consent to the company seeking medical
              information from any doctor or from a hospital who at any time has
              attended on the life to be insured/ proposer or from any past or
              present employer concerning anything which affects the physical or
              mental health of the life to be assured / proposer and seeking
              information from any insurance company to which an application for
              insurance on the life to be assured / proposer has been made for
              the purpose of underwriting the proposal and / or claim
              settlement.
            </p>
            <p>
              I / We authorize the company to share information pertaining to my
              proposal including the medical records for the sole purpose of
              proposal underwriting and / or claims settlement and with any
              Governmental and / or Regulatory authority.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>
              PROHIBITION OF REBATES (before payment of premium)
            </h3>
          </Grid>
          <Grid xs={12}>
            <p style={{ color: "#000000B3", marginBottom: "8px" }}>
              (Under Section 41 of Insurance Act 1938)
            </p>
            <p>
              No person shall allow or offer to allow, either directly or
              indirectly, as an inducement to any person to take out or renew or
              continue an insurance in respect of any kind of risk relating to
              lives or property in India, any rebate of the whole or part of the
              commission payable or any rebate of the premium shown on the
              policy, nor shall any person taking out or renewing or continuing
              a policy accept any rebate, except such rebate as may be allowed
              in accordance with the published prospectuses or tables of the
              Insurer.
            </p>
            <p>
              Any person making default in complying with the provisions of this
              section shall be liable for a penalty which may extend to ten lakh
              rupees.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>Terms and conditions of use</h3>
          </Grid>
          <Grid xs={12}>
            <p>
              The following terms and conditions shall govern use of the website
              www.careinsurance.com and all other URLs forming part thereof
              (hereinafter referred to as "the Sites"). The Sites are owned and
              managed by Care Health Insurance Limited (formerly known as
              Religare Health Insurance Company Limited) incorporated under the
              Indian Companies Act, 1956 (hereinafter referred to as "us or we"
              in the first person and or as "Care Health Insurance Limited"
              (wherever the context so requires). These terms and conditions of
              use form the entirety of any express or implied contract that may
              or may not be deemed to exist between your-self and us.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>Policy Documents</h3>
          </Grid>
          <Grid xs={12}>
            <p>
              Your policy documents will be sent to your registered email id and
              mobile number (through SMS & Whats-App)
            </p>
            <p>
              I hereby agree to receive policy & product related information,
              incl. but not limited to new product launches, offers and
              promotions, through email, SMS & Whatsapp or any other medium.
            </p>
            <p>
              Physical Policy documents will be dispatched on specific request
              only.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>Prohibited Conduct:</h3>
          </Grid>
          <Grid xs={12}>
            <p style={{ color: "#000000B3", marginBottom: "8px" }}>
              You will not host, display, upload, modify, publish, transmit,
              update or share any information that:
            </p>
            <p>
              belongs to another person and to which the user does not have any
              right is defamatory, obscene, pornographic, paedophilic, invasive
              of another privacy including bodily privacy, insulting or
              harassing on the basis of gender, libellous, racially or
              ethnically objectionable, relating or encouraging money laundering
              or gambling, or otherwise inconsistent with or contrary to the
              laws in force; is harmful to child;
            </p>
            <p>
              infringes any patent, trademark, copyright or other proprietary
              rights;
            </p>
            <p>violates any law for the time being in force;</p>
            <p>
              deceives or misleads the addressee about the origin of the message
              or knowingly and intentionally communicates any information which
              is patently false or misleading in nature but may reasonably be
              perceived as a fact;
            </p>
            <p>impersonates another person;</p>
            <p>
              threatens the unity, integrity, defence, security or sovereignty
              of India, friendly relations with foreign States, decency or
              morality in relation to contempt of court, defamation or public
              order, or causes incitement to the commission of any cognizable
              offence or prevents investigation of any offence or is insulting
              other nation;
            </p>
            <p>
              contains software virus or any other computer code, file or
              program designed to interrupt to destroy or limit the
              functionality of any computer resource;
            </p>
            <p>
              is patently false and untrue, and is written or published in any
              form, with the intent to mislead or harass a person, entity or
              agency for financial gain or to cause any injury to any person.
            </p>
            <p>
              You shall be solely liable for any damages resulting from any
              violation of the foregoing restrictions, or any other harm
              resulting from your posting of content to this Site / Mobile
              Application.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>
              Termination; Agreement Violations:
            </h3>
          </Grid>
          <Grid xs={12}>
            <p>
              MOFSL has the right to terminate the access or usage rights of the
              users to the computer resource immediately or remove non-compliant
              information or both, as the case may be in case of non-compliance
              with rules and regulations, privacy policy or user agreement for
              access or usage of the computer resource of MOFSL.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>Local Laws:</h3>
          </Grid>
          <Grid xs={12}>
            <p>
              MOFSL is compliant as per the local, state and central laws. We
              endeavor to provide information under its control or possession,
              or assistance to any Government agency which is lawfully
              authorized to investigate or protect cyber security activities,
              for the purposes of verification of identity, or for the
              prevention, detection, investigation, or prosecution, of offenses
              under any law for the time being in force, or for cyber security
              incidents as soon as possible, but not later than seventy-two
              hours of the receipt of an order or as per the timelines specified
              in any government or regulatory order. Accordingly, our Grievance
              officer and our legal team are authorized to receive and
              acknowledge any order, notice or direction issued by the
              Appropriate Government, any competent authority, or a court of
              competent jurisdiction.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>Dispute Resolution:</h3>
          </Grid>
          <Grid xs={12}>
            <p>
              MOFSL shall within twenty-four hours from the receipt of a
              complaint made by an individual or any person on his behalf in
              relation to any content which is prima facie in any material
              exposes the private area of such individual, shows such individual
              in full or partial nudity or shows or depicts such individual in
              any sexual act or conduct, or is in the nature of impersonation in
              an electronic form, including artificially morphed images of such
              individual, take all reasonable and practicable measures to remove
              or disable access to such content which is hosted, stored,
              published or transmitted by it.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>Use of LIBL Platform:</h3>
          </Grid>
          <Grid xs={12}>
            <p>
              MOFSL has implemented a mechanism for the receipt of complaints
              which may enable the individual or person to provide details, as
              may be necessary, in relation to such content or communication
              link.
            </p>
            <p>
              Further, you understand that once you place a cancellation request
              within the free look period, the policy gets cancelled and entire
              premium is refunded to you subject to deduction of (i) charges
              pertaining to medical tests conducted (ii) administrative and
              service cost like stamp duty etc. and; (iii) charges for mortality
              for the period the policy was in force. Please note such deduction
              is at the sole discretion of the insurer.
            </p>
            <p>
              In case of a refund of premium due to an erroneous transaction or
              cancellation of the insurance policy, the premium paid for an
              insurance policy will be refunded via cheque or direct credit as
              per the policy of the insurance company that you have chosen to
              buy the product from.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"16px"}>
          <Grid xs={12}>
            <h3 style={{ marginBottom: "8px" }}>Cookies</h3>
          </Grid>
          <Grid xs={12}>
            <p className="mb-2">
              Motilal Oswal Financial Service Limited uses the technology known
              as "cookies" to track usage patterns, traffic trends and user
              behaviour, as well as to record other information from the
              website. Many content adjustments and customer service
              improvements are made based on the data derived from cookies. The
              information we collect from cookies will not be used to create
              profiles of users and will only be used in aggregate form.
            </p>
            <p className="mb-2">
              The User may set his/her/its browser to refuse cookies. If the
              User so chooses, the User may still gain access to most of the
              Website, but the User may not be able to conduct certain types of
              transactions (such as shopping) or take advantage of some of the
              interactive elements offered.
            </p>
            <p>
              If the User uses any of the sharing features that may be offered
              by this Site, the User’s friend's email address will not be
              retained on Motilal Oswal Financial Service Limited Website or
              used in any way by Motilal Oswal Financial Service Limited or its
              group companies/affiliates
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default PrivacyPolicy;
