import { PHP_DOMAIN, NODE_DOMAIN } from "./Domain";
const DOMAIN = process.env.REACT_APP_BACKEND_URL;
const Domain1 = "https://devapiev.Jioinsure.in";
export const CommonURLs = {
  // GENERATE_TOKEN: `${Domain1}/api/users/generate-token.json`,
  GENERATE_TOKEN: `${PHP_DOMAIN}/api/users/generate-token.json`,

  // SUBMIT_CLAIM: `${DOMAIN}/api/users/send-claim-page-email.json`,

  //Premium Confiramtion
  PREMIUM_CONFIRMATION: `${DOMAIN}/api/policies/payment-confirmation.json`,

  CONTACT_US: `${NODE_DOMAIN}/api/v1/email/contact`,
  SUBMIT_CLAIM: `${NODE_DOMAIN}/api/v1/email/claims`,
  SUBMIT_COMPLAINT: `${NODE_DOMAIN}/api/v1/email/complain`,
};
